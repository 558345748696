import { useParams } from "react-router-dom";

export const useTranslation = (all?: any) => {
  let { locale } = useParams();
  if (["fr", "en", "bg"].indexOf(locale || "") === -1) {
    locale = "en";
  }
  const t = (translations: string | Object) => {
    if (typeof translations === "string") {
      return all[locale!][translations];
    } else {
      return (translations as any)[locale!] || (translations as any).en;
    }
  };
  return { t };
};
