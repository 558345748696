import EuropeLicence from "../blocks/europe-licence";
import CTA from "../components/_cta";
import Footer from "../components/_footer";
import Header from "../components/_header";
import { useTranslation } from "../services/i18n";
import {
  getWebsiteContactEmail,
  getWebsiteName,
  getWebsiteSupportEmail,
} from "../services/site-name";
import { WhiteLabel } from "../white-label";

export default function AboutUs() {
  const { t } = useTranslation({
    en: {
      about_us: "About us",
      intro:
        "Created in 2022, " +
        getWebsiteName() +
        " is one of the first fast crypto-asset exchange platforms operating from Europe.",
    },
    fr: {
      about_us: "À propos",
      intro:
        "Créé en 2022, " +
        getWebsiteName() +
        " est l'une des première plateformes d'échange de crypto-actifs rapide exerçant depuis l'Europe.",
    },
    bg: {
      about_us: "За нас",
      intro:
        "Създаден през 2022 г., " +
        getWebsiteName() +
        " е една от първите бързи платформи за обмяна на крипто активи, работеща от Европа.",
    },
  });

  document.title = "" + getWebsiteName() + " - " + t("about_us");

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {getWebsiteName()} - {t("about_us")}
                </span>
              </h1>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                {t("intro")}
              </p>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                {WhiteLabel.brand.company_name}, {WhiteLabel.brand.registration}
                .
              </p>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                Customer Services:
                <br />
                Weekdays 09:00-17:00 (CET)
                <br />
                {getWebsiteSupportEmail()}
                <br />
              </p>
              <p className="mt-8 text-xl text-gray-500 leading-8">
                Address and contact details:
                <br />
                {getWebsiteContactEmail()}
                <br />
                <br />
                {WhiteLabel.brand.address_line_1}
                <br />
                {WhiteLabel.brand.address_line_2}
              </p>
            </div>
          </div>
        </div>

        <EuropeLicence />

        <CTA />
      </main>

      <Footer />
    </div>
  );
}
