import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function AmlPolicy() {
  document.title = getWebsiteName() + " - Politique LCB-FT";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Politique LCB-FT
                </span>
              </h1>

              <br />
              <br />

              <section>
                <h2>
                  <strong>
                    Politique de Lutte contre le Blanchiment d'Argent (LCB) et
                    le Financement du Terrorisme (FT) de {getWebsiteName()}{" "}
                    Limitée
                  </strong>
                </h2>
                <p className="my-3">
                  Le blanchiment d'argent est défini comme le processus par
                  lequel l'identité des produits de la criminalité est si
                  dissimulée qu'elle donne l'impression de revenus légitimes.
                  Les criminels ciblent spécifiquement les entreprises de
                  services financiers à travers lesquelles ils tentent de
                  blanchir les produits de la criminalité sans que les
                  entreprises n'en aient connaissance ou suspicion.
                </p>
                <p className="my-3">
                  En réponse à l'ampleur et à l'effet du blanchiment d'argent,
                  l'Union européenne a adopté des directives visant à lutter
                  contre le blanchiment d'argent et le terrorisme. Ces
                  directives, ainsi que les règlements, les règles et les lignes
                  directrices de l'industrie, constituent la pierre angulaire de
                  nos obligations en matière de LCB/FT et définissent les
                  infractions et les sanctions en cas de non-conformité.
                </p>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>
                    Politique de Lutte contre le Blanchiment d'Argent (LCB) :
                  </strong>
                </h2>
                <p className="my-3">
                  La Politique de Lutte contre le Blanchiment d'Argent (LCB) de{" "}
                  {getWebsiteName()} a pour objectif de prévenir le blanchiment
                  d'argent en respectant les normes européennes en matière de
                  lutte contre le blanchiment d'argent et le financement du
                  terrorisme, notamment la nécessité de disposer de systèmes et
                  de contrôles adéquats pour atténuer le risque que l'entreprise
                  soit utilisée pour faciliter la criminalité financière. Cette
                  politique LCB énonce les normes minimales à respecter,
                  notamment :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Établir et maintenir une Approche Fondée sur les Risques
                    (AFR) pour l'évaluation et la gestion des risques de
                    blanchiment d'argent et de financement du terrorisme
                    auxquels l'entreprise est confrontée ;
                  </li>
                  <li>
                    Établir et maintenir des procédures de Connaissance du
                    Client (CDD) basées sur les risques, d'identification, de
                    vérification et de Connaissance de Votre Client (KYC), y
                    compris une diligence accrue pour les clients présentant un
                    risque plus élevé, tels que les Personnes Politiquement
                    Exposées (PPE) ;
                  </li>
                  <li>
                    Établir et maintenir des systèmes et des procédures basés
                    sur les risques pour la surveillance de l'activité continue
                    des clients ;
                  </li>
                  <li>
                    Établir des procédures de signalement des activités
                    suspectes en interne et aux autorités répressives
                    compétentes, le cas échéant ;
                  </li>
                  <li>
                    Conserver des dossiers appropriés pendant les périodes
                    minimales prescrites ;
                  </li>
                  <li>
                    Fournir une formation et sensibiliser l'ensemble des
                    employés concernés.
                  </li>
                </ul>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>Politique de Sanctions :</strong>
                </h2>
                <p className="my-3">
                  Il est interdit à {getWebsiteName()} de traiter avec des
                  individus, des entreprises et des pays figurant sur des listes
                  de sanctions prescrites. Par conséquent, {getWebsiteName()}{" "}
                  effectuera des vérifications à l'encontre des listes de
                  sanctions des Nations Unies, de l'Union européenne, du Trésor
                  britannique et de l'Office of Foreign Assets Control (OFAC)
                  des États-Unis dans toutes les juridictions où nous opérons.
                </p>
              </section>
              <br />
              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
