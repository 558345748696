import { useParams } from "react-router";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";
import FeeScheduleBg from "./fee-schedule-bg";
import FeeScheduleFr from "./fee-schedule-fr";

export default function FeeSchedule() {
  let { locale } = useParams();

  if (locale === "fr") {
    return <FeeScheduleFr />;
  }

  if (locale === "bg") {
    return <FeeScheduleBg />;
  }

  document.title = getWebsiteName() + " - Fee Schedule";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Fee Schedule
                </span>
              </h1>
              <br />
              <br />

              <p>
                Note that the fees below are the default fees. They can be
                customized for each client upon request.
              </p>
              <br />

              <p>
                <strong>Minimum orders values</strong>
              </p>

              <p>
                <strong>BTC</strong> - 0.0003 BTC
                <br />
                <strong>EUR</strong> - 15 EUR
                <br />
                <strong>USD</strong> - 15 USD
                <br />
                <strong>GBP</strong> - 15 GBP
              </p>

              <br />

              <p>
                <strong>Order fees</strong>
              </p>

              <p>
                We apply a fixed fee of 0.6% on all buy and sell orders. The
                fees are always applied on the base currency.
              </p>

              <br />

              <p>
                In the case of payment links using intermediate currency,
                potential market price changes up to 1% and order fees presented
                in the previous section will be at our charges and replaced by a
                unique fee of 7%. A payment request of 100 EUR using ETH
                currency will always end up with a final fee of 7 EUR and so the
                merchant will receive 93 EUR.
              </p>

              <br />

              <p>
                <strong>Other fees</strong>
              </p>

              <p>
                Withdrawal fees are 3 EUR for EUR, 3 USD for USD, 3 GBP for GBP
                and excludes receiver bank related fees
              </p>

              <br />

              <p>
                Card deposit fees are dependant on our payment processor and are
                subject to change. The fees will be displayed during the payment
                process. The fees will be added (and not deducted) to your
                initial deposit request.
              </p>

              <br />

              <p>
                We do not apply fees for all user to user transfers using the
                same currency.
              </p>

              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
