import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function FeeSchedule() {
  document.title = getWebsiteName() + " - Тарифна схема";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Тарифна схема
                </span>
              </h1>
              <br />
              <br />

              <p>
                Моля, обърнете внимание, че таксите по-долу са тарифите по
                подразбиране. Те могат да бъдат персонализирани за всеки клиент
                по заявка.
              </p>
              <br />

              <p>
                <strong>Минимални стойности на поръчките</strong>
              </p>

              <p>
                <strong>BTC</strong> - 0,0003 BTC
                <br />
                <strong>EUR</strong> - 15 EUR
                <br />
                <strong>USD</strong> - 15 USD
                <br />
                <strong>GBP</strong> - 15 GBP
              </p>

              <br />

              <p>
                <strong>Такси за поръчки</strong>
              </p>

              <p>
                Ние прилагаме фиксирана такса от 0,6% за всички поръчки за
                покупка и продажба. Таксите се прилагат винаги към основната
                валута.
              </p>

              <br />

              <p>
                В случай на плащателни връзки с използване на преходна валута,
                потенциалните промени в цената на пазара до 1% и таксите за
                поръчка, представени в предишния раздел, ще бъдат по наша сметка
                и заменени с еднократна такса от 7%. Заявка за плащане на
                стойност 100 EUR с използване на ETH винаги ще завърши с крайна
                такса от 7 EUR и така търговецът ще получи 93 EUR.
              </p>

              <br />

              <p>
                <strong>Други такси</strong>
              </p>

              <p>
                Таксите за изтегляне са 3 EUR за EUR, 3 USD за USD, 3 GBP за GBP
                и не включват таксите, свързани с банката на получателя.
              </p>

              <br />

              <p>
                Таксите за депозит с карта зависят от нашия обработващ център за
                плащания и са предмет на промяна. Таксите ще бъдат показани по
                време на процеса на плащане. Таксите ще бъдат добавени (а не
                изваждани) от вашата първоначална заявка за депозит.
              </p>

              <br />

              <p>
                Ние не прилагаме такси за трансфери между потребители със същата
                валута.
              </p>

              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
