import MainBigView from "../blocks/big-buy-sell-crypto";
import EuropeLicence from "../blocks/europe-licence";
import Services from "../blocks/services";
import CTA from "../components/_cta";
import Footer from "../components/_footer";
import Header from "../components/_header";
import { useTranslation } from "../services/i18n";
import { getWebsiteName } from "../services/site-name";

export default function Home() {
  const { t } = useTranslation({
    en: {
      title: "Home",
    },
    fr: {
      title: "Accueil",
    },
    bg: {
      title: "Начало",
    },
  });

  if (
    typeof window !== "undefined" &&
    (document.location.hostname === "metawallet.be" ||
      document.location.hostname === "metawallet.lu")
  ) {
    document.location.href = "https://metawallet.plus";
  }

  document.title = `${getWebsiteName()} - ${t("title")}`;

  return (
    <div className="bg-white relative overflow-hidden">
      <Header />

      <main>
        <MainBigView />
        <Services />
        <EuropeLicence />
        <CTA />
      </main>

      <Footer />
    </div>
  );
}
