import { useParams } from "react-router-dom";
import React from "react";
import { useTranslation } from "../services/i18n";
import { getWebsiteName } from "../services/site-name";

export default function OTCService() {
  const router = useParams();
  const { t } = useTranslation({
    en: {
      title: "We provide {otc} services",
      description:
        getWebsiteName() +
        " offers an over-the-counter (OTC) that allows traders to execute orders off the open " +
        getWebsiteName() +
        " exchange. We offer deeper liquidity as well as a more private, bespoke service for institutional clients and high net-worth individuals needing to fill large orders.",
      contact_us: "Contact us now",
      "advantage.secure": "More secure",
      "advantage.secure.description":
        "The most secure way for crypto investors to make important transactions.",
      "advantage.cheaper": "Best prices",
      "advantage.cheaper.description":
        "Best prices and transaction fees, and real-time trading.",
      "advantage.customized": "Customized relationship",
      "advantage.customized.description":
        "A customized relationship with a trusted broker.",
      "advantage.privacy": "Privacy and anonymity",
      "advantage.privacy.description":
        "Higher privacy and anonymity than other exchanges.",
    },
    fr: {
      title: "Nous fournissons des services {otc}",
      description:
        getWebsiteName() +
        " offre un service sur Over-The-Counter (OTC) qui vous permet d'exécuter des ordres en dehors de l'exchange " +
        getWebsiteName() +
        ". Nous offrons une liquidité plus importante ainsi qu'un service privilégié et sur mesure pour les clients institutionnels et les particuliers fortunés ayant besoin de passer des commandes de montant important.",
      contact_us: "Contactez-nous maintenant",
      "advantage.secure": "Plus sécurisé",
      "advantage.secure.description":
        "Le moyen le plus sécurisé pour les investisseurs de cryptomonnaie effectuant d'importantes transactions.",
      "advantage.cheaper": "Meilleurs prix",
      "advantage.cheaper.description":
        "De meilleurs prix et frais de transactions, et un trading en temps réel.",
      "advantage.customized": "Relation personnalisée",
      "advantage.customized.description":
        "Une relation personnalisée avec un courtier de confiance.",
      "advantage.privacy": "Confidentialité et anonymat",
      "advantage.privacy.description":
        "Confidentialité et anonymat supérieurs par rapport aux autres plateformes d’échanges.",
    },
    bg: {
      title: "Предлагаме {otc} услуги",
      description:
        getWebsiteName() +
        " предлага услуга извън борсова търговия (OTC), която позволява на трейдърите да изпълняват поръчки извън отворената борса на " +
        getWebsiteName() +
        ". Предлагаме по-голяма ликвидност, както и по-частна, персонализирана услуга за институционални клиенти и лица с висок доход, които имат нужда от изпълнение на големи поръчки.",
      contact_us: "Свържете се с нас сега",
      "advantage.secure": "По-сигурно",
      "advantage.secure.description":
        "Най-сигурният начин за крипто инвеститорите да извършват важни транзакции.",
      "advantage.cheaper": "Най-добри цени",
      "advantage.cheaper.description":
        "Най-добри цени и такси за транзакции, и търговия в реално време.",
      "advantage.customized": "Персонализирана връзка",
      "advantage.customized.description":
        "Персонализирана връзка с доверен брокер.",
      "advantage.privacy": "Поверителност и анонимност",
      "advantage.privacy.description":
        "По-висока поверителност и анонимност от другите борси.",
    },
  });

  const metrics = [
    {
      title: t("advantage.secure"),
      emphasis: t("advantage.secure.description"),
    },
    {
      title: t("advantage.cheaper"),
      emphasis: t("advantage.cheaper.description"),
    },
    {
      title: t("advantage.customized"),
      emphasis: t("advantage.customized.description"),
    },
    {
      title: t("advantage.privacy"),
      emphasis: t("advantage.privacy.description"),
    },
  ];

  return (
    <div className="relative overflow-hidden	">
      <div className="mb-40 relative mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
            <span className="block xl:inline">
              {t("title").split("{otc}")[0]}
              <span className=" text-indigo-600 xl:inline">
                Over-the-Counter (OTC)
              </span>{" "}
              {t("title").split("{otc}")[1]}
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            {t("description")}
          </p>

          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map((item) => (
              <p key={item.title}>
                <span className="block text-xl font-bold text-black">
                  {item.title}
                </span>
                <span className="mt-1 block text-base">
                  <span className="font-normal text-gray-600">
                    {item.emphasis}
                  </span>
                </span>
              </p>
            ))}
          </div>
        </div>

        <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <a
              href={"/" + (router.locale || "en") + "/contact"}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
            >
              {t("contact_us")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
