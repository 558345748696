import { useLocation, useNavigate, useParams } from "react-router-dom";

export default (props: { iconOnly?: boolean }) => {
  const router = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getLang = (locale: string) => {
    let str =
      ({ fr: "Français", en: "English", bg: "Български" } as any)[locale] ||
      locale.toLocaleUpperCase();
    if (props.iconOnly) {
      str =
        ({ fr: "🇫🇷 FR", en: "🇺🇸 EN", bg: "🇧🇬 BG" } as any)[locale] ||
        locale.toLocaleUpperCase();
    }
    return str;
  };

  return (
    <select
      id="language"
      name="language"
      className={
        props.iconOnly
          ? "border border-transparent text-base text-gray-700 focus:outline-none focus:ring-white focus:border-white sm:text-sm"
          : "appearance-none block w-full bg-none bg-gray-700 border border-transparent rounded-md py-2 pl-3 pr-10 text-base text-white focus:outline-none focus:ring-white focus:border-white sm:text-sm"
      }
      defaultValue={router.locale || "en"}
      onChange={(e) =>
        navigate(
          location.pathname?.replace(
            `/${router.locale || "en"}/`,
            `/${e.target.value}/`
          ) || "/"
        )
      }
    >
      {["en", "fr", "bg"].map((locale) => (
        <option key={locale} value={locale}>
          {getLang(locale)}
        </option>
      ))}
    </select>
  );
};
