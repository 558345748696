import {
  CurrencyEuroIcon,
  GlobeAltIcon,
  HandIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "../services/i18n";
import { getWebsiteLogoPrefix } from "../services/site-name";
import { WhiteLabel } from "../white-label";

export default function MainBigView() {
  const { t } = useTranslation(WhiteLabel.locale.main_big_view);

  return (
    <div className="relative overflow-hidden	">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <div className="lg:visible invisible bg-blue-gradient diagonal-background-left"></div>
      </div>

      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 left-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-12 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="#FFFFFF44" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>

      <div
        className="lg:visible invisible relative flex justify-between items-center max-w-7xl mx-auto md:justify-start md:space-x-10 lg:px-8"
        aria-hidden="true"
      >
        <img
          className="animate-float absolute top-0 left-6 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          src={"/assets/" + getWebsiteLogoPrefix() + "previews/pay-company.svg"}
          alt="Payment view"
          style={{ width: 400, top: 24 }}
        />
      </div>

      <div className="mb-40 relative mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-right">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
            <span className="block xl:inline">
              {t("title").split("{crypto}")[0]} <br />
              <span className="text-indigo-600 xl:inline">Crypto</span>{" "}
              {t("title").split("{crypto}")[1].split("{europe}")[0]}
              <span className="text-indigo-600 xl:inline">Europe</span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mr-0">
            {t("description")}
          </p>
        </div>
        <br />
        <br />

        <div className="mt-10 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mr-0">
        {WhiteLabel.features.wallets !== false &&  (<div className="relative mt-6">
            <dt>
              <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                <CurrencyEuroIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
                {t("solutions.crypto_wallet")}
              </p>
            </dt>
            <dd className="mr-16 text-base text-gray-400  text-right">
              {t("solutions.crypto_wallet.description")}
            </dd>
          </div>)}

          {WhiteLabel.features.abroad !== false && (<div className="relative mt-6">
            <dt>
              <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
                {t("solutions.worldwide_payments")}
              </p>
            </dt>
            <dd className="mr-16 text-base text-gray-400  text-right">
              {t("solutions.worldwide_payments.description")}
            </dd>
          </div>)
}

{WhiteLabel.features.payment !== false && (<div className="relative mt-6">
  <dt>
    <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
      <SwitchVerticalIcon className="h-6 w-6" aria-hidden="true" />
    </div>
    <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
      {t("solutions.instant_payments")}
    </p>
  </dt>
  <dd className="mr-16 text-base text-gray-400  text-right">
    {t("solutions.instant_payments.description")}
  </dd>
</div>)}

{WhiteLabel.features.otc !== false && (<div className="relative mt-6">
  <dt>
    <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
      <HandIcon className="h-6 w-6" aria-hidden="true" />
    </div>
    <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
      {t("solutions.otc")}
    </p>
  </dt>
  <dd className="mr-16 text-base text-gray-400  text-right">
    {t("solutions.otc.description")}
  </dd>
</div>)}
        </div>
      </div>
    </div>
  );
}
