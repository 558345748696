import React from "react";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteHost, getWebsiteName } from "../../services/site-name";

export default function Privacy() {
  document.title = `${getWebsiteName()} - Политика за Бисквитки`;

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Политика за Бисквитки на {getWebsiteName()}
                </span>
              </h1>
              <br />
              <br />
              <p>
                Това е Политиката за Бисквитки на {getWebsiteName()}, достъпна
                от {getWebsiteHost()}
              </p>
              <br />
              <p>
                <strong>Какво Са Бисквитките</strong>
              </p>
              <p>
                Както е обичайна практика почти на всички професионални
                уебсайтове, този сайт използва бисквитки, които са малки
                файлове, изтегляни на вашия компютър, с цел подобряване на
                вашето преживяване. Тази страница описва каква информация
                събират, как я използваме и защо понякога трябва да съхраняваме
                тези бисквитки. Ще ви информираме също как можете да
                предотвратите съхранението на тези бисквитки, но това може да
                повлияе на или деактивира някои функции на сайта.
              </p>
              <br />{" "}
              <p>
                <strong>Как Използваме Бисквитките</strong>
              </p>
              <p>
                Използваме бисквитки по няколко причини, както е подробно
                описано по-долу. За съжаление, в повечето случаи няма стандартни
                опции за деактивиране на бисквитките, без да деактивирате
                напълно функционалността и характеристиките, които те добавят
                към този сайт. Препоръчително е да оставите всички бисквитки
                активирани, ако не сте сигурни дали ви са необходими или не,
                защото може да се използват за предоставяне на услуга, която
                използвате.
              </p>
              <br />
              <p>
                <strong>Деактивиране на Бисквитките</strong>
              </p>
              <p>
                Можете да предотвратите създаването на бисквитки, като
                настройвате настройките на вашия браузър (вижте Помощта на
                браузъра си за това как да направите това). Бъдете внимателни,
                че деактивирането на бисквитки ще засегне функционалността на
                този и много други уебсайтове, които посещавате. Обикновено
                деактивирането на бисквитки ще доведе и до деактивирането на
                определени функции и характеристики на този сайт. Поради това е
                препоръчително да не деактивирате бисквитките.
              </p>
              <br />
              <p>
                <strong>Бисквитките, Които Ние Задаваме</strong>
              </p>
              <ul>
                <li>
                  <p>• Бисквитки, свързани с акаунта</p>
                  <p>
                    Ако създадете акаунт при нас, ще използваме бисквитки за
                    управление на процеса на регистрация и общо администриране.
                    Тези бисквитки обикновено ще бъдат изтрити, когато се
                    отпишете, но в някои случаи те може да останат след това, за
                    да запомнят настройките на вашия сайт, когато сте извън
                    системата.
                  </p>
                </li>

                <li>
                  <p>• Бисквитки, свързани с влизането</p>
                  <p>
                    Използваме бисквитки, когато сте влезли в системата, така че
                    можем да си спомним този факт. Това ви предпазва от нуждата
                    да влизате в системата всеки път, когато посетите нова
                    страница. Тези бисквитки обикновено се изтриват или
                    изчистват, когато се отпишете, за да се гарантира, че можете
                    да получите достъп само до ограничени функции и зони, когато
                    сте влезли в системата.
                  </p>
                </li>
              </ul>
              <br />
              <p>
                <strong>Бисквитки от Трети Стори</strong>
              </p>
              <p>
                В някои специални случаи използваме също бисквитки, предоставяни
                от утвърдени трети страни. Следващата секция описва бисквитките
                от трети страни, с които може да се срещнете чрез този сайт.
              </p>
              <ul>
                <li>
                  <p>
                    Този сайт използва Google Analytics, който е едно от
                    най-широко разпространените и най-доверени решения за анализ
                    на уеба, за да ни помогне да разберем как използвате сайта и
                    как можем да подобрим вашето преживяване. Тези бисквитки
                    могат да следят неща като продължителността на престоя ви на
                    сайта и страниците, които посещавате, за да можем да
                    продължим да произвеждаме привлекателно съдържание.
                  </p>
                  <p>
                    За повече информация относно бисквитките на Google
                    Analytics, вижте официалната страница на Google Analytics.
                  </p>
                </li>
              </ul>
              <br />
              <p>
                <strong>Повече Информация</strong>
              </p>
              <p>
                Надяваме се, че това ви е разяснило нещата, както беше споменато
                преди, ако има нещо, по което не сте сигурни, дали ви е
                необходимо или не, обикновено е по-добре да оставите бисквитките
                активирани, ако има взаимодействие с една от функциите, които
                използвате на нашия сайт.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
