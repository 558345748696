import OTCService from "../../blocks/big-otc-service";
import EuropeLicence from "../../blocks/europe-licence";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function OTC() {
  document.title = "" + getWebsiteName() + " - Solutions - OTC";

  return (
    <div className="bg-white">
      <Header />

      <OTCService />

      <EuropeLicence />

      <Footer />
    </div>
  );
}
