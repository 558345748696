import { useParams } from "react-router-dom";
import { useTranslation } from "../services/i18n";

export default function CTA() {
  const router = useParams();
  const { t } = useTranslation({
    en: {
      ready_to_start: "Ready to get started?",
      schedule_a_demo: "Get in touch to learn more.",
      contact_us: "Contact us",
    },
    fr: {
      ready_to_start: "Prêt à démarrer ?",
      schedule_a_demo: "Contactez-nous pour en savoir plus.",
      contact_us: "Contactez-nous",
    },
    bg: {
      ready_to_start: "Готови да започнете?",
      schedule_a_demo: "Свържете се с нас, за да научите повече.",
      contact_us: "Свържете се с нас",
    },
  });

  return (
    <div className="bg-white">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">{t("ready_to_start")}</span>
          <span className="block bg-gradient-to-r from-indigo-700 to-blue-600 bg-clip-text text-transparent">
            {t("schedule_a_demo")}
          </span>
        </h2>
        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
          <a
            href={"/" + (router.locale || "en") + "/contact"}
            className="flex items-center justify-center bg-gradient-to-r from-indigo-700 to-blue-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-indigo-800 hover:to-blue-700"
          >
            {t("contact_us")}
          </a>
        </div>
      </div>
    </div>
  );
}
