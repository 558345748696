import React from "react";
import EuropeLicence from "../../blocks/europe-licence";
import CryptoPayments from "../../blocks/big-crypto-payments";
import CTA from "../../components/_cta";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function OTC() {
  document.title = "" + getWebsiteName() + " - Solutions - Merchant";

  return (
    <div className="bg-white">
      <Header />

      <CryptoPayments />

      <EuropeLicence />

      <CTA />

      <Footer />
    </div>
  );
}
