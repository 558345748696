import { ChevronDownIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import { useTranslation } from "../services/i18n";
import { getWebsiteName, getWebsiteSupportEmail } from "../services/site-name";
import { WhiteLabel } from "../white-label";
import LanguageSelector from "./_language-selector";

export default () => {
  const router = useParams();
  const { t } = useTranslation({
    en: {
      footer: "Footer",
      language: "Language",
      solutions: "Solutions",
      solutions_crypto_wallet: "Crypto Wallet",
      solutions_merchants: "Merchants",
      solutions_otc: "OTC",
      resources: "Resources",
      resources_about: "About us",
      resources_api: "API Docs",
      legal: "Legal",
      aml_policy: "AML Policy",
      legal_privacy: "Privacy",
      legal_terms: "Terms",
      fee_schedule: "Fee Schedule",
      legal_cookies: "Cookies",
      get_in_touch: "Get in touch",
      get_in_touch_description:
        "Schedule a demo with us or learn more about our products and services.",
      schedule_demo: "Schedule a demo",
    },
    fr: {
      footer: "Pied de page",
      language: "Langue",
      solutions: "Solutions",
      solutions_crypto_wallet: "Crypto Wallet",
      solutions_merchants: "E-Commerce",
      solutions_otc: "OTC",
      resources: "Resources",
      resources_about: "À propos de nous",
      resources_api: "Documentation API",
      legal: "Légal",
      aml_policy: "Politique AML",
      legal_privacy: "Confidentialité",
      legal_terms: "Conditions",
      fee_schedule: "Tarifs",
      legal_cookies: "Cookies",
      get_in_touch: "Contactez-nous",
      get_in_touch_description:
        "Prenez rendez-vous avec nous pour en savoir plus sur nos produits et services.",
      schedule_demo: "Planifier une démo",
    },
    bg: {
      footer: "Краен срок",
      language: "Език",
      solutions: "Решения",
      solutions_crypto_wallet: "Крипто Портфейл",
      solutions_merchants: "Електронна търговия",
      solutions_otc: "OTC",
      resources: "Ресурси",
      resources_about: "Относно нас",
      resources_api: "Документация API",
      legal: "Правно",
      aml_policy: "AML Политика",
      legal_privacy: "Поверителност",
      legal_terms: "Условия",
      fee_schedule: "Такси",
      legal_cookies: "Бисквитки",
      get_in_touch: "Свържете се с нас",
      get_in_touch_description:
        "Запишете се за демонстрация с нас или научете повече за нашите продукти и услуги.",
      schedule_demo: "Запиши демо",
    },
  });

  const navigation = {
    solutions: [
      WhiteLabel?.features?.wallets !== false && { name: t("solutions_crypto_wallet"), href: "/solutions/wallet" },
      WhiteLabel?.features?.ecommerce !== false && { name: t("solutions_merchants"), href: "/solutions/merchant" },
      { name: t("solutions_otc"), href: "/solutions/otc" },
    ],
    resources: [
      { name: t("resources_about"), href: "/about_us" },
      WhiteLabel?.features?.fees !== false && { name: t("fee_schedule"), href: "/legal/fee-schedule", external: false },
    ],
    legal: [
      { name: t("legal_terms"), href: "/legal/terms" },
      { name: t("aml_policy"), href: "/legal/aml-policy" },
      { name: t("legal_privacy"), href: "/legal/privacy" },
      { name: t("legal_cookies"), href: "/legal/cookies" },
    ],
  };

  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        {t("footer")}
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {t("solutions")}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.filter(a=>a).map((item: any) => (
                    <li key={item.name}>
                      <a
                        href={"/" + (router.locale || "en") + item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {t("resources")}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.resources.filter(a=>a).map((item: any) => (
                    <li key={item.name}>
                      <a
                        target={item.external ? "_blank" : ""}
                        href={"/" + (router.locale || "en") + item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  {t("legal")}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.filter(a=>a).map((item: any) => (
                    <li key={item.name}>
                      <a
                        href={"/" + (router.locale || "en") + item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              {t("language")}
            </h3>
            <form className="mt-4 sm:max-w-xs">
              <fieldset className="w-full">
                <label htmlFor="language" className="sr-only">
                  {t("language")}
                </label>
                <div className="relative">
                  <LanguageSelector />
                  <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                    <ChevronDownIcon
                      className="h-4 w-4 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              {t("get_in_touch")}
            </h3>
            <p className="mt-2 text-base text-gray-300">
              {t("get_in_touch_description")}
            </p>
          </div>
          <div className="mt-4 sm:flex sm:max-w-md lg:mt-0">
            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                onClick={() =>
                  typeof window !== "undefined" &&
                  (document.location = "/contact")
                }
                type="submit"
                className="w-full bg-gradient-to-r from-indigo-700 to-blue-600 bg-origin-border
                hover:from-indigo-800 hover:to-blue-700 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-700"
              >
                {t("schedule_demo")}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2"></div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} {getWebsiteName()} All rights
            reserved.
            <br />
            {WhiteLabel.brand.registration && (
              <>
                {WhiteLabel.brand.company_name} is{" "}
                {WhiteLabel.brand.registration}
              </>
            )}
            <br />
            <a href="https://www.visa.com/" target="_blank">
              <img
                src="/assets/card-visa-white.png"
                alt="Visa"
                className="h-6 inline-block mt-2 mr-3"
              />
            </a>
            <a href="https://www.mastercard.com/" target="_blank">
              <img
                src="/assets/card-mastercard.svg"
                alt="Mastercard"
                className="h-8 inline-block mt-2 mr-2"
              />
            </a>
            <br />
            <br />
            Customer Services
            <br />
            Weekdays 09:00-17:00 (CET)
            <br />
            {getWebsiteSupportEmail()}
            <br />
            <br />
            {WhiteLabel.brand.address_line_1}
            <br />
            {WhiteLabel.brand.address_line_2}
          </p>
        </div>
      </div>
    </footer>
  );
};
