import { WhiteLabel } from "../white-label";

export const getWebsiteName = () => {
  let val = WhiteLabel.brand.name;
  document.title = document.title.replace("MetaWallet", val);
  let link: any = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = WhiteLabel.brand.favicon;

  return val;
};

export const getWebsiteHost = () => {
  return WhiteLabel.brand.host;
};

export const getWebsiteContactEmail = () => {
  return WhiteLabel.brand.contact_email;
};

export const getWebsiteSupportEmail = () => {
  return WhiteLabel.brand.support_email;
};

export const getWebsiteLogoPrefix = () => {
  return WhiteLabel.brand.logo_prefix;
};
