import Footer from "../../components/_footer";
import Header from "../../components/_header";
import {
  getWebsiteContactEmail,
  getWebsiteHost,
  getWebsiteName,
} from "../../services/site-name";

export default function Privacy() {
  document.title = getWebsiteName() + " - Conditions d'utilisation";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Conditions d'utilisation
                </span>
              </h1>

              <br />
              <br />
              <section>
                <h2>
                  <strong>Acceptation des conditions d'utilisation</strong>
                </h2>
                <p className="my-3">
                  En utilisant ce site Web ("Site"), en vous inscrivant à un
                  compte {getWebsiteName()} ("Compte") ou en utilisant tout
                  autre service, produit, données, contenu ou autre matériel
                  disponible sur le Site ("Services") et fourni par{" "}
                  {getWebsiteName()} LTD ("nous", "nous", "notre"), vous ("vous,
                  votre ou vous-même") acceptez d'accepter et de vous conformer
                  aux termes et conditions d'utilisation énoncés ci-dessous
                  ("Conditions d'utilisation" ou "Conditions"). Vous devez lire
                  l'intégralité des Conditions d'utilisation attentivement avant
                  d'utiliser ce Site ou l'un des Services.
                </p>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>
                    SI VOUS N'ACCEPTEZ PAS LES CONDITIONS D'UTILISATION
                    CI-DESSOUS, VOUS NE POUVEZ PAS ACCÉDER À CE SITE NI UTILISER
                    LES SERVICES.
                  </strong>
                </h2>
                <div className="divider divider--light"></div>
                <p className="my-3">
                  Ces Conditions d'utilisation ont été mises à jour pour la
                  dernière fois le 21 septembre 2022. {getWebsiteName()} se
                  réserve le droit de modifier, d'ajouter ou de supprimer des
                  parties de ces Conditions à tout moment et à sa seule
                  discrétion. Vous serez informé de toute modification à
                  l'avance via votre Compte. À la suite d'une telle
                  notification, il vous appartient de consulter les Conditions
                  modifiées. Votre utilisation continue du Site et des Services
                  après la publication d'un avis de modification des Conditions
                  signifie que vous acceptez et acceptez les modifications, et
                  que toutes les transactions ultérieures effectuées par vous
                  seront soumises aux Conditions modifiées.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    À propos de {getWebsiteName()} et des Services
                  </strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} Limited est enregistrée en Bulgarie.
                </p>
                <p className="my-3">
                  Les Services permettent aux utilisateurs enregistrés des
                  Services ("Membres") de :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Acheter ou vendre des actifs virtuels et des actifs
                    numériques à d'autres Membres en échange d'autres actifs
                    virtuels ou numériques ou de devises fiduciaires ;
                  </li>
                  <li>
                    Conclure des accords pour stocker des actifs virtuels et des
                    actifs numériques répertoriés sur le Site auprès d'un
                    dépositaire tiers ;
                  </li>
                  <li>
                    Retirer tout solde de devises fiduciaires détenu sur votre
                    Compte ;
                  </li>
                  <li>
                    Transférer des actifs virtuels et des actifs numériques vers
                    un portefeuille ou vers d'autres utilisateurs de tels actifs
                    virtuels et actifs numériques en dehors du Site ; et
                  </li>
                  <li>Utiliser des actifs virtuels pour acheter des biens.</li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Conditions générales du Site</strong>
                </h2>
                <p className="my-3">
                  Selon votre pays de résidence, d'incorporation ou de siège
                  social, vous pourriez ne pas être en mesure d'utiliser toutes
                  les fonctions du Site ou des Services. Il est de votre
                  responsabilité de suivre les règles et lois de votre pays de
                  résidence et/ou du pays à partir duquel vous accédez à ce Site
                  et aux Services. Tant que vous acceptez et respectez ces
                  Conditions d'utilisation, {getWebsiteName()} vous accorde le
                  droit personnel, non exclusif, non transférable, non cessible
                  et limité d'entrer et d'utiliser le Site et les Services.
                </p>
                <p className="my-3">
                  Tous nos Services sont fournis sans garantie d'aucune sorte,
                  expresse ou implicite, et en particulier sans garantie
                  implicite de qualité marchande et d'adéquation à un usage
                  particulier. Nous ne garantissons pas que les Services ainsi
                  que le Site seront disponibles à 100 % du temps pour répondre
                  à vos besoins. Nous nous efforcerons de vous fournir les
                  Services dès que possible, mais il n'y a aucune garantie que
                  l'accès ne sera pas interrompu ou qu'il n'y aura pas de
                  retards, de pannes, d'erreurs, d'omissions ou de perte
                  d'informations transmises.
                </p>
                <p className="my-3">
                  Nous mettrons tout en œuvre pour nous assurer que les Services
                  et le Site peuvent être accessibles par vous conformément aux
                  présentes Conditions d'utilisation. Cependant, nous pouvons
                  suspendre l'utilisation du Site et des Services pour des
                  raisons de maintenance et nous nous efforcerons de vous donner
                  un préavis raisonnable à cet égard. Vous reconnaissez dans ce
                  contexte que cela peut ne pas être possible en cas d'urgence,
                  et vous acceptez les risques liés au fait que vous ne pourrez
                  pas toujours utiliser les Services et le Site, ou effectuer
                  des transactions urgentes avec votre Compte.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Votre Compte {getWebsiteName()}</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Ouverture de votre Compte</strong>
                </p>
                <p className="my-3">
                  En ouvrant un Compte, vous déclarez expressément et
                  garantissez :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Que vous avez accepté d'être légalement lié par ces
                    Conditions d'utilisation ;
                  </li>
                  <li>
                    Si vous êtes une personne physique, que vous avez au moins
                    18 ans ;
                  </li>
                  <li>
                    Si vous êtes une personne morale ou agissant au nom d'une
                    personne morale, que vous êtes dûment autorisé à agir au nom
                    et au nom de la personne morale, et à lier cette dernière de
                    manière valable ;
                  </li>
                  <li>
                    Que vous avez la pleine capacité d'accepter ces Conditions
                    d'utilisation, soit en votre nom propre, soit au nom et au
                    nom de la personne morale que vous représentez, et
                    d'effectuer des transactions impliquant des actifs virtuels
                    et d'autres actifs numériques ; et
                  </li>
                  <li>
                    Que vous reconnaissez et acceptez que toutes les
                    informations fournies, communiquées et échangées avec vous,
                    y compris, mais sans s'y limiter, toute information
                    contractuelle, exonérations de responsabilité, actions
                    marketing et assistance client, sont menées en anglais et
                    que vous renoncez expressément à toute autre langue.
                  </li>
                </ul>
                <p className="mt-3 text-base font-medium">
                  <strong>Comment nous vérifions votre Compte</strong>
                </p>
                <p className="my-3">
                  La création et l'utilisation de votre Compte sont soumises à
                  des vérifications, conformément aux obligations légales et
                  réglementaires qui nous incombent. Vous acceptez de nous
                  fournir les informations que nous demandons aux fins de
                  vérification d'identité, de conformité avec les règles de
                  connaissance du client, ainsi que de détection du blanchiment
                  d'argent, du financement du terrorisme, de la fraude ou de
                  toute autre infraction financière. Les informations demandées
                  peuvent inclure des données personnelles (veuillez vous
                  référer à notre{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Politique de confidentialité
                  </a>
                  ). En nous fournissant les informations que nous demandons,
                  vous confirmez qu'elles sont véritables et exactes, et
                  acceptez de nous informer en cas de changement de ces
                  informations. Votre Compte sera bloqué jusqu'à ce que nous
                  soyons satisfaits des informations que vous avez fournies et
                  que nous déterminions à notre seule discrétion qu'elles sont
                  suffisantes pour valider votre Compte. Pendant ce temps, vous
                  ne serez pas autorisé à résilier votre Compte ou à demander la
                  suppression des données personnelles traitées dans le cadre
                  des opérations de vérification.
                </p>

                <p className="my-3">
                  Nous nous réservons expressément le droit d'annuler et/ou de
                  résilier les Comptes qui n'ont pas été vérifiés par le Membre
                  malgré les efforts déployés de bonne foi par nous pour vous
                  contacter afin d'obtenir cette vérification ("Comptes non
                  vérifiés"). Tous les Comptes non vérifiés inactifs pendant une
                  période de 6 mois ou plus sont en outre soumis à un transfert
                  à un tiers dépositaire (le "Dépôt non vérifié"), et ne seront
                  plus entretenus ni sous notre responsabilité légale.
                  L'administrateur/fiduciaire du Dépôt non vérifié fera tous les
                  efforts raisonnables supplémentaires requis par la loi pour
                  déterminer et contacter chaque propriétaire de Compte non
                  vérifié et, après un effort et un temps appropriés, il se peut
                  que nous soyons obligés de convertir les actifs virtuels
                  résiduels ou d'autres actifs numériques en monnaie fiduciaire
                  et de les envoyer à une autorité nationale responsable de la
                  conservation de ces fonds.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Financer votre compte</strong>
                </p>
                <p className="my-3">
                  Si vous avez choisi de financer votre Compte via des dépôts
                  par virement, les fonds provenant d'un dépôt par virement
                  seront mis à disposition pour le trading sur votre Compte dès
                  qu'ils seront compensés. Veuillez noter que les délais de
                  règlement des dépôts dépendent des jours fériés bancaires, des
                  processus des banques partenaires et des processus de votre
                  banque. De plus, les délais de règlement des dépôts peuvent
                  être retardés en raison de problèmes techniques.
                </p>
                <p className="my-3">
                  Nous n'acceptons pas les dépôts en monnaie fiduciaire
                  provenant de comptes bancaires tiers. Le nom de votre compte
                  bancaire lié doit correspondre au nom vérifié sur votre Compte{" "}
                  {getWebsiteName()}. Si un virement bancaire est reçu à partir
                  d'un compte bancaire tiers, nous le rejetterons et le
                  retournerons immédiatement.
                </p>
                <p className="my-3">
                  Votre Compte, une fois financé, ne génère pas et ne générera
                  pas d'intérêts.
                </p>
                <p className="my-3">
                  Veuillez noter que les délais de règlement des dépôts
                  dépendent des jours fériés bancaires, des processus des
                  banques partenaires et des processus de votre banque. De plus,
                  les délais de règlement des dépôts peuvent être retardés en
                  raison de problèmes techniques.
                </p>
                <p className="my-3">
                  Nous nous réservons le droit d'augmenter ou de diminuer vos
                  limites quotidiennes, mensuelles et globales, ou de les rendre
                  temporairement ou définitivement indisponibles. Cela peut être
                  fait sans préavis et à notre seule discrétion.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Politique de remboursement</strong>
                </p>
                <p className="my-3">
                  Toutes les ventes et achats d'actifs virtuels et d'autres
                  actifs numériques effectués avec n'importe quelle méthode de
                  paiement sont finaux. Nous n'acceptons aucun retour ni ne
                  fournissons de remboursements pour votre achat d'actifs
                  virtuels ou d'autres actifs numériques de {getWebsiteName()},
                  sauf dans les cas prévus dans ces Conditions d'utilisation.
                </p>
                <p className="my-3">
                  Dans le cas où l'échange de monnaie fiduciaire contre des
                  actifs virtuels ou d'autres actifs numériques n'est pas traité
                  en raison de problèmes techniques ou d'une forte volatilité
                  des prix lors de la procédure d'achat par carte,{" "}
                  {getWebsiteName()} créditera le compte du Membre avec de la
                  monnaie fiduciaire.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Maintenir votre compte</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Gardez vos informations à jour
                </p>
                <p className="my-3">
                  Nous sommes vigilants pour maintenir la sécurité de notre Site
                  et des Services. En vous inscrivant chez nous, vous acceptez
                  de fournir à {getWebsiteName()} des informations actuelles,
                  précises et complètes vous concernant, y compris, pour les
                  entités légales, des informations sur les directeurs, le
                  personnel clé et les propriétaires effectifs, ainsi que la
                  résolution de l'entreprise pour ouvrir le Compte, comme le
                  demande le processus d'inscription, et de maintenir ces
                  informations à jour. Si vous êtes une personne physique, vous
                  acceptez en outre de n'utiliser aucun Compte autre que le
                  vôtre ni d'accéder au Compte d'un autre Membre à tout moment,
                  ni d'aider d'autres personnes à obtenir un accès non autorisé.
                </p>
                <p className="my-3">
                  La création ou l'utilisation de Comptes sans obtenir au
                  préalable notre autorisation expresse entraînera la suspension
                  immédiate de tous lesdits Comptes, ainsi que de toutes les
                  offres d'achat/vente en attente. Toute tentative de le faire
                  ou d'aider d'autres personnes (Membres ou autres), ou la
                  distribution d'instructions, de logiciels ou d'outils à cette
                  fin, entraînera la résiliation des Comptes de ces Membres. La
                  résiliation n'est pas le seul recours en cas de violation de
                  ces règles, et nous pouvons choisir de prendre des mesures
                  supplémentaires contre vous.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Maintenez la confidentialité de vos informations de compte
                </p>
                <p className="my-3">
                  Vous êtes également responsable de maintenir la
                  confidentialité de vos informations de Compte, y compris votre
                  mot de passe, de protéger vos actifs virtuels et actifs
                  numériques, ainsi que toutes les activités, y compris les
                  transactions, qui sont enregistrées sur votre Compte (y
                  compris toutes les instructions à {getWebsiteName()}). Toutes
                  les actions sur le Site, les transactions, les commandes, les
                  instructions et les opérations initiées depuis votre Compte ou
                  avec votre mot de passe sont (1) considérées comme ayant été
                  effectuées par vous et (2) irrévocables une fois validées avec
                  votre mot de passe ou effectuées par le biais de votre Compte.
                  En cas d'activité suspecte liée à votre Compte, nous pouvons,
                  mais nous ne sommes pas tenus de le faire, vous demander des
                  informations supplémentaires, y compris des documents
                  d'authentification, et geler toutes les transactions en
                  attente pendant notre examen. Vous êtes tenu de respecter ces
                  demandes de sécurité ou d'accepter la résiliation de votre
                  Compte. Vous devez informer immédiatement {getWebsiteName()}{" "}
                  de toute utilisation non autorisée de votre Compte ou de votre
                  mot de passe, ou de toute autre violation de la sécurité, en
                  utilisant notre{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    formulaire de contact
                  </a>
                  . Tout Membre qui enfreint ces règles peut être résilié, et
                  par la suite tenu responsable des pertes subies par{" "}
                  {getWebsiteName()} ou tout utilisateur du Site et des
                  Services.
                </p>
                <p className="my-3">
                  Vous reconnaissez que vous créditerez uniquement votre Compte{" "}
                  {getWebsiteName()} à partir d'un compte bancaire qui est à
                  votre nom, et que vous donnerez des instructions de paiement à
                  partir de votre Compte {getWebsiteName()} uniquement vers un
                  compte bancaire qui est à votre nom.
                </p>
                <p className="my-3">
                  Nous nous réservons le droit d'annuler les transferts
                  effectués par ou envoyés à des tiers.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Gardez vos actifs virtuels et numériques en sécurité
                </p>
                <p className="my-3">
                  Le rôle de {getWebsiteName()} se limite à vous fournir une
                  plateforme via les Services dans le cadre de laquelle nous
                  agissons uniquement en tant que prestataire de services, en
                  tant qu'agent et conformément à vos Instructions pour la
                  gestion et le transfert d'actifs virtuels et d'autres actifs
                  numériques.
                </p>
                <p className="my-3">
                  Afin que nous puissions gérer le Site, fournir les Services et
                  établir une relation directe entre vous et le(s) gardien(s)
                  tiers chargé(s) de détenir les actifs virtuels ou autres
                  actifs numériques pertinents en tant que gardien en votre nom,
                  vous nommez {getWebsiteName()} en tant qu'agent avec une
                  autorité d'agence perpétuelle et irrévocable (à l'exception de
                  la résiliation du Compte) pour agir en votre nom en tant
                  qu'agent pour :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Établir une relation légale de garde avec un ou des gardiens
                    tiers pour la garde de vos actifs virtuels ou autres actifs
                    numériques, ainsi que nommer des personnes autorisées au
                    sein de {getWebsiteName()} pour donner des instructions à de
                    tels gardiens tiers en votre nom ;
                  </li>
                  <li>
                    Accéder et/ou transférer des actifs virtuels ou d'autres
                    actifs numériques comme nécessaire pour le fonctionnement du
                    Site et la fourniture des Services, y compris :
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      Stocker et garder confidentiels les clés privées par
                      rapport aux actifs virtuels ou autres actifs numériques ;
                    </li>
                    <li>
                      Transférer des cryptomonnaies à des gardiens tiers (qui
                      ont à leur tour le droit de nommer des sous-gardiens et de
                      fournir des services de gardiennage à vous par le biais de
                      ces sous-gardiens ou autres mandataires ou agents) ; et
                    </li>
                    <li>
                      Déplacer des cryptomonnaies entre les portefeuilles chauds
                      et froids détenus par ces gardiens, afin de permettre une
                      liquidité suffisante du portefeuille chaud pour soutenir
                      les opérations sur le Site tout en fournissant en même
                      temps la sécurité supplémentaire du stockage à froid.
                    </li>
                  </ul>
                  <li>
                    Partager vos informations avec des fournisseurs de services
                    de gardiennage tiers afin qu'ils puissent être engagés pour
                    vous fournir des services de gardiennage (voir ci-dessous) ;
                  </li>
                  <li>
                    Transférer des actifs virtuels et des actifs numériques vers
                    un portefeuille ou vers d'autres utilisateurs de tels actifs
                    virtuels et actifs numériques en dehors du Site ;
                  </li>
                  <li>
                    Entreprendre les événements suivants qui agissent, ou
                    pourraient agir comme des restrictions, ou affectent vos
                    droits :
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      &nbsp;Blocage des comptes Ripple conformément à la
                      politique de Ripple Labs ;
                    </li>
                    <li>
                      &nbsp;Résiliation de notre relation avec vous et/ou votre
                      Compte ;
                    </li>
                    <li>
                      &nbsp;Suspension de notre relation avec vous et/ou votre
                      Compte ;
                    </li>
                    <li>
                      &nbsp;Interdiction du wash trading et d'autres activités ;
                    </li>
                    <li>
                      &nbsp;Exigence de tailles minimales de transaction ;
                    </li>
                    <li>
                      &nbsp;Blocage des comptes dans certaines circonstances ;
                    </li>
                    <li>
                      &nbsp;Application de périodes d'indisponibilité et de
                      temps d'arrêt du service ;
                    </li>
                    <li>
                      &nbsp;Conformité aux ordres des agences d'application de
                      la loi ou aux actions réglementaires ;
                    </li>
                    <li>
                      &nbsp;Compensation de toute obligation en cours que vous
                      avez envers {getWebsiteName()}, ce qui peut inclure la
                      vente de tout actif virtuel ou autre actif numérique pour
                      couvrir l'obligation en cours que vous avez ; et
                    </li>
                  </ul>
                  <li>
                    Agir autrement uniquement sur vos instructions concernant
                    les actifs virtuels ou autres actifs numériques, y compris
                    en ce qui concerne :
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      &nbsp;Achat ou vente d'actifs virtuels ou d'autres actifs
                      numériques ;
                    </li>
                    <li>
                      &nbsp;L'accès ou le transfert d'actifs virtuels ou
                      d'autres actifs numériques entre des portefeuilles ; et/ou
                    </li>
                    <li>
                      &nbsp;Toute autre fin ou action comme vous l'avez
                      instruit.
                    </li>
                  </ul>
                </ul>
                <p className="my-3">
                  En acceptant ces Conditions d'utilisation, vous reconnaissez
                  et acceptez qu'en le faisant, vous nous donnez des
                  instructions de manière irrévocable et continue pour
                  entreprendre toutes les actions mentionnées ci-dessus en votre
                  nom. Vous ne pouvez pas révoquer cette Instruction continue en
                  fermant votre Compte, auquel cas elle expirera uniquement
                  lorsque votre Compte sera fermé.
                </p>
                <p className="my-3">
                  En conséquence de cette relation d'agence limitée, nous
                  n'avons pas, et vous acceptez que nous n'avons pas, de droits,
                  de titres ou d'intérêts dans les actifs virtuels ou autres
                  actifs numériques détenus en votre nom par les gardiens tiers.
                </p>
                <p className="my-3">
                  Nous ne sommes pas un intermédiaire, n'acquérons aucun droit,
                  titre ou intérêt dans, et n'assumons aucun droit ou contrôle
                  sur les actifs virtuels ou autres actifs numériques ou tout
                  autre actif de Membre autre que pour les actions prises
                  conformément à notre autorité d'agence et à vos instructions.
                  En conséquence, nous ne transférerons ni ne vendrons aucun
                  actif virtuel ou autre actif numérique autrement qu'en
                  conformité avec vos instructions (y compris toutes les
                  instructions imposées par la loi, l'autorité de régulation ou
                  les agences d'application de la loi).
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Fermeture de votre Compte</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Vous pouvez fermer votre Compte à tout moment.
                </p>
                <p className="my-3">
                  Vous pouvez résilier cet accord avec {getWebsiteName()} et
                  fermer votre Compte à tout moment en nous contactant{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    ici
                  </a>
                  , après règlement de toutes les transactions en attente.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Nous pouvons fermer, suspendre ou geler votre Compte avec
                  préavis.
                </p>
                <p className="my-3">
                  Nous pouvons, en donnant un avis, à notre seule discrétion :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Limiter, suspendre ou résilier votre accès au Service, au
                    Site et/ou à votre Compte ;
                  </li>
                  <li>
                    Interdire l'accès au Site et à son contenu, à ses services
                    et à ses outils ;
                  </li>
                  <li>Reporter ou supprimer du contenu hébergé ; et</li>
                  <li>
                    Prendre des mesures techniques et légales pour empêcher les
                    Membres d'accéder au Site s'ils créent des problèmes ou des
                    responsabilités légales possibles, enfreignent les droits de
                    propriété intellectuelle de tiers ou agissent de manière
                    incompatible avec la lettre ou l'esprit de ces Conditions.
                  </li>
                </ul>
                <p className="my-3">
                  De plus, nous pouvons, dans des circonstances appropriées et à
                  notre discrétion, suspendre ou résilier les comptes des
                  membres pour toute raison, y compris, sans limitation :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Tentatives d'accès non autorisé au Site ou au compte d'un
                    autre membre ou fourniture d'une assistance à d'autres
                    personnes tentant de le faire ;
                  </li>
                  <li>
                    Contournement des fonctionnalités de sécurité logicielle
                    limitant l'utilisation ou protégeant tout contenu ;
                  </li>
                  <li>
                    Utilisation du Service pour effectuer des activités
                    illégales telles que le blanchiment d'argent, des opérations
                    de jeu illégales, le financement du terrorisme ou d'autres
                    activités criminelles ;
                  </li>
                  <li>Violations de ces Conditions d'utilisation ;</li>
                  <li>
                    Non-paiement ou paiement frauduleux pour des transactions ;
                  </li>
                  <li>Difficultés opérationnelles inattendues ; ou</li>
                  <li>
                    À la demande des forces de l'ordre ou d'autres agences
                    gouvernementales.
                  </li>
                </ul>
                <p className="my-3">
                  La suspension ou l'escrow d'un compte n'affectera pas le
                  paiement des frais dus pour les transactions passées.
                </p>
                <p className="my-3">
                  À la résiliation ou à la suspension, vous devez fournir les
                  détails d'un compte bancaire valide pour permettre le
                  transfert de toute devise créditée sur votre compte. Ce compte
                  bancaire doit vous appartenir. Les actifs virtuels ou autres
                  actifs numériques ne peuvent être transférés que vers un
                  compte bancaire valide après conversion en une devise fiat.
                  Nous transférerons les devises dès que possible après votre
                  demande et dans les délais que nous avons spécifiés.
                </p>
                <p className="my-3">
                  Nous organiserons l'envoi du solde créditeur de votre compte ;
                  cependant, dans certaines circonstances, plusieurs
                  intermédiaires peuvent être impliqués dans un paiement
                  international, et ceux-ci ou la banque bénéficiaire peuvent
                  déduire des frais. Nous ferons tous les efforts raisonnables
                  pour vous informer de ces frais avant l'envoi de votre
                  paiement ; cependant, lorsque cela ne peut être évité, vous
                  reconnaissez que ces frais ne peuvent pas toujours être
                  calculés à l'avance, et vous acceptez d'en être responsable.
                </p>
                <p className="my-3">
                  Lors de la fermeture de compte, tout montant inférieur à 25
                  USD/EUR ne sera pas remboursé.
                </p>
                <p className="my-3">
                  Nous nous réservons le droit de ne pas convertir les actifs
                  virtuels ou autres actifs numériques en fiat et de les
                  retourner à un portefeuille appartenant au membre.
                </p>
                <p className="my-3">
                  Nous nous réservons également le droit de geler les comptes
                  Ripple conformément à la politique de Ripple Labs, qui est
                  entrée en vigueur le 15 septembre 2014. Veuillez consulter
                  l'explication fournie par Ripple Labs ci-dessous :
                </p>
                <p className="my-3">
                  <em>
                    L'extension du protocole de gel donne aux passerelles la
                    possibilité de 1) geler globalement tous leurs fonds émis ou
                    2) geler les fonds émis à un utilisateur spécifique. Les
                    fonds gelés ne peuvent être renvoyés qu'à la passerelle
                    émettrice. La fonction de gel global permet à une passerelle
                    de geler tous les soldes qu'elle émet. La passerelle peut
                    toujours effectuer des paiements. Les comptes détenant des
                    soldes gelés peuvent retourner les fonds à la passerelle.
                    Cette fonction est utile pour migrer les utilisateurs d'un
                    compte à un autre et pour protéger les utilisateurs en cas
                    de compromission du compte de la passerelle. Le gel
                    individuel est principalement destiné à se conformer aux
                    exigences réglementaires, qui peuvent varier d'une
                    juridiction à l'autre. Il permet également aux passerelles
                    de geler les émissions de comptes individuels afin
                    d'enquêter sur des activités suspectes. Ces fonctionnalités
                    permettent aux passerelles de mieux fonctionner en
                    conformité avec les lois et réglementations.
                  </em>
                </p>
                <p className="my-3">
                  Pour éviter toute confusion, toute action entreprise par{" "}
                  {getWebsiteName()} pour suspendre, retarder, annuler ou geler
                  toute instruction et/ou suspendre votre compte n'affectera pas
                  vos droits, titres ou intérêts concernant vos actifs virtuels
                  et autres actifs numériques détenus par des gardiens tiers en
                  votre nom, mais peut affecter la capacité de{" "}
                  {getWebsiteName()} à exécuter vos instructions, ce que vous
                  reconnaissez et acceptez par la présente.
                </p>
              </section>

              <br />
              <br />
              <section>
                <h2>
                  <strong>Trading avec {getWebsiteName()}</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Risques</strong>
                </p>
                <p className="my-3">
                  Le trading de biens et de produits, réels ou virtuels, ainsi
                  que de devises virtuelles comporte des risques importants. Les
                  prix peuvent et fluctuent effectivement chaque jour. De telles
                  variations de prix peuvent augmenter ou diminuer la valeur de
                  vos actifs à tout moment. Toute devise - virtuelle ou non -
                  peut être soumise à d'importantes fluctuations de valeur,
                  voire devenir sans valeur. Il existe un risque inhérent de
                  subir des pertes en achetant, vendant ou échangeant quoi que
                  ce soit sur un marché.
                </p>
                <p className="my-3">
                  Le trading d'Actifs Virtuels et d'Actifs Numériques comporte
                  également des risques spéciaux qui ne sont généralement pas
                  partagés avec les monnaies officielles, les biens ou les
                  matières premières sur un marché. Contrairement à la plupart
                  des monnaies, qui sont garanties par des gouvernements ou
                  d'autres entités légales, ou par des matières premières telles
                  que l'or ou l'argent, les Actifs Virtuels et les Actifs
                  Numériques sont un type unique d'actif, garanti par la
                  technologie et la confiance. Il n'y a pas de banque centrale
                  capable d'émettre davantage de cryptomonnaie ou de prendre des
                  mesures correctives pour protéger la valeur des Actifs
                  Virtuels et des Actifs Numériques en cas de crise.
                </p>
                <p className="my-3">
                  Au lieu de cela, les Actifs Virtuels et les Actifs Numériques
                  constituent un système mondial encore largement autonome et
                  largement non réglementé de sociétés de cryptomonnaie et
                  d'individus. Les traders font confiance à un système
                  numérique, décentralisé et partiellement anonyme qui repose
                  sur le réseau pair-à-pair et la cryptographie pour maintenir
                  son intégrité.
                </p>
                <p className="my-3">
                  Le trading d'Actifs Virtuels et d'Actifs Numériques est
                  souvent vulnérable aux bulles irrationnelles (ou rationnelles)
                  ou à la perte de confiance, ce qui pourrait faire chuter la
                  demande par rapport à l'offre. Par exemple, la confiance dans
                  les Actifs Virtuels et les Actifs Numériques pourrait
                  s'effondrer en raison de changements inattendus imposés par
                  des développeurs de logiciels ou d'autres, d'une répression
                  gouvernementale, de la création de devises alternatives
                  concurrentes supérieures, ou d'une spirale déflationniste ou
                  inflationniste. La confiance pourrait également s'effondrer en
                  raison de problèmes techniques : si l'anonymat du système est
                  compromis, si de l'argent est perdu ou volé, ou si des pirates
                  ou des gouvernements parviennent à empêcher toute transaction
                  de s'effectuer.
                </p>
                <p className="my-3">
                  Il peut y avoir des risques supplémentaires que nous n'avons
                  pas prévus ou identifiés dans nos Conditions d'Utilisation.
                </p>
                <p className="my-3">
                  Vous devriez évaluer attentivement si votre situation
                  financière et votre tolérance au risque sont adaptées à
                  l'achat, la vente ou le trading d'Actifs Virtuels ou d'Actifs
                  Numériques.
                </p>
                <p className="my-3">
                  Nous utilisons nos prestataires bancaires afin de faciliter la
                  réception de la monnaie fiduciaire des Membres et les
                  paiements à d'autres Membres. Nos prestataires bancaires NE
                  transfèrent PAS, n'échangent PAS et ne fournissent PAS de
                  services liés aux Actifs Virtuels ou aux Actifs Numériques.
                  Votre monnaie fiduciaire sera détenue dans un compte bancaire
                  au nom de {getWebsiteName()} avec la monnaie fiduciaire
                  d'autres Membres. Dans la mesure du possible dans une
                  juridiction pertinente qui reconnaît le concept de compte
                  client, nous informerons le prestataire bancaire concerné que
                  le compte bancaire est un compte client dans lequel nous
                  détenons de la monnaie fiduciaire au nom des Membres. Vous
                  resterez de toute façon le propriétaire bénéficiaire de la
                  monnaie fiduciaire que nous détenons en votre nom. Nous
                  conserverons des enregistrements détaillés de toute la monnaie
                  fiduciaire détenue au nom des Membres. Bien que{" "}
                  {getWebsiteName()} fasse preuve d'une diligence raisonnable
                  dans la désignation de ses prestataires bancaires, en cas
                  d'insolvabilité d'un prestataire bancaire ou d'entame d'une
                  procédure d'insolvabilité dans une juridiction pertinente,{" "}
                  {getWebsiteName()} peut n'avoir qu'une créance non garantie
                  contre le prestataire bancaire, et les soldes de monnaie
                  fiduciaire des Membres peuvent être en danger, sous réserve
                  des protections prévues par la loi dans la juridiction
                  pertinente.
                </p>
                <p className="my-3">
                  Nous collaborons avec des gardiens tiers afin de détenir des
                  Actifs Virtuels et des Actifs Numériques en votre nom. Vos
                  Actifs Virtuels et Actifs Numériques seront détenus en votre
                  nom dans un portefeuille au nom de {getWebsiteName()} avec
                  d'autres Actifs Virtuels et/ou Actifs Numériques des Membres.
                  Ils peuvent également être détenus de temps à autre dans le
                  portefeuille avec les Actifs Virtuels et/ou Actifs Numériques
                  de {getWebsiteName()}, qui reflètent les frais de{" "}
                  {getWebsiteName()}, mais qui sont enregistrés sur le grand
                  livre de {getWebsiteName()} comme appartenant à{" "}
                  {getWebsiteName()}, et que vous demandez à {getWebsiteName()}{" "}
                  de récupérer en les retirant du portefeuille. Nous
                  conserverons des enregistrements détaillés de tous les Actifs
                  Virtuels et Actifs Numériques que les Membres détiennent
                  auprès des gardiens tiers que nous avons nommés.
                </p>
                <p className="my-3">
                  Comme indiqué ci-dessus, {getWebsiteName()} n'agira que sur
                  vos Instructions concernant les Actifs Virtuels et les Actifs
                  Numériques, et n'acquiert aucun autre droit, titre ou intérêt
                  à leur égard. Bien que {getWebsiteName()} fasse preuve d'une
                  diligence raisonnable dans la désignation des gardiens tiers,
                  en cas d'insolvabilité d'un gardien tiers ou d'entame d'une
                  procédure d'insolvabilité dans une juridiction pertinente,
                  vous ne pouvez avoir qu'une créance non garantie contre le
                  gardien tiers, et les Actifs Virtuels ou Actifs Numériques des
                  Membres peuvent être en danger, sous réserve des protections
                  prévues par la loi dans la juridiction pertinente.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Risques liés à la Fraude et aux Arnaques.
                </p>
                <p className="my-3">
                  Avant d'utiliser nos Services et en confirmant ces Conditions
                  d'Utilisation, vous acceptez et reconnaissez que :
                </p>
                <ol className="list text--regular text--thin" type="a">
                  <li>
                    {getWebsiteName()} ne sera pas responsable de toute perte ou
                    dommage subi par vous en raison de toute activité illicite
                    ou frauduleuse de tiers - vous êtes seul responsable de
                    votre comportement et de l'utilisation de votre Compte ;
                  </li>
                  <li>
                    {getWebsiteName()} ne fait pas la promotion ni n'exploite de
                    cadeaux - les promotions officielles et les programmes de
                    parrainage ne sont communiqués que par le biais des canaux
                    appartenant à {getWebsiteName()}, par exemple le Site ;
                  </li>
                  <li>
                    {getWebsiteName()} ne communique qu'avec vous via l'adresse
                    @ {getWebsiteContactEmail().split("@")[1]};
                  </li>
                  <li>
                    {getWebsiteName()} ne vous appellera jamais de manière
                    inattendue et sans préavis, quelle que soit la cause ou la
                    raison ;
                  </li>
                  <li>
                    {getWebsiteName()} ne vous demandera jamais de divulguer vos
                    informations d'authentification sensibles, quelle que soit
                    la cause ou la raison, et ne vous demandera jamais un accès
                    à distance à votre appareil ni de modifier vos paramètres de
                    sécurité ;
                  </li>
                  <li>
                    Vos informations d'identification de compte ne doivent être
                    utilisées que par vous et vous ne devez pas divulguer vos
                    informations d'identification de connexion à une tierce
                    personne ou entité. Vous veillerez à ce que l'adresse e-mail
                    et le numéro de téléphone que vous avez utilisés pour
                    enregistrer un Compte {getWebsiteName()} soient
                    exclusivement utilisés et conservés en privé par vous ;
                  </li>
                  <li>
                    Vous configurerez l'authentification à deux facteurs
                    uniquement sur les appareils que vous possédez et contrôlez
                    exclusivement, et vous ne permettrez à personne d'accéder ou
                    d'utiliser les appareils que vous utilisez pour
                    l'authentification à deux facteurs ;
                  </li>
                  <li>
                    Toute tentative de fournir des instructions à une personne
                    (Membres ou tiers), ou de distribuer des instructions, des
                    logiciels ou des outils, liés au processus d'inscription, au
                    fonctionnement du Compte et aux mesures de sécurité, quelle
                    que soit la finalité, peut entraîner la résiliation des
                    Comptes du contrevenant et des Membres affectés, à la seule
                    discrétion de {getWebsiteName()}.
                  </li>
                </ol>

                <p className="mt-3 text-base font-medium">
                  <strong>Utilisation Acceptable de Nos Services</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Manipulation de Marché
                </p>
                <p className="my-3">
                  Nous ne vous autorisons pas à effectuer des transactions
                  croisées, que ce soit seul ou en collusion avec d'autres, ou à
                  passer un ordre qui entraînerait une auto-exécution,
                  c'est-à-dire, où le même trader ou groupe de traders agirait à
                  la fois en tant que preneur et apporteur d'affaires pour la
                  transaction.
                </p>
                <p className="my-3">
                  Si deux ordres de parties opposées entraîneraient une
                  transaction croisée, les deux ordres sont soumis à des mesures
                  spécifiques, allant de l'examen, de l'annulation, de la
                  suspension ou de l'interdiction de négocier sur le Site. Si
                  deux ordres de même quantité entraîneraient une
                  auto-exécution, les deux ordres sont soumis à des mesures
                  spécifiques, allant de l'examen, de l'annulation, de la
                  suspension ou de l'interdiction de négocier sur le Site.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Manipulation de Marché
                </p>
                <p className="my-3">
                  Il est interdit de participer ou de tenter de participer à la
                  manipulation de marché. Cette interdiction s'applique à tout
                  type d'activité qui a pour conséquence ou pour but de :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    &nbsp;Contrôler ou manipuler artificiellement le prix ou le
                    volume de trading de l'une des devises virtuelles
                    répertoriées sur le Site ; et
                  </li>
                  <li>
                    &nbsp;Manipuler un indice, y compris, mais sans s'y limiter,
                    la transmission d'informations fausses ou trompeuses, ou
                    toute autre action manipulant le calcul d'un indice.
                  </li>
                </ul>
                <p className="my-3">
                  La manipulation de marché entraînera des mesures spécifiques,
                  allant de l'examen, de l'annulation des ordres, de la
                  suspension ou de l'interdiction de négocier sur le Site et de
                  la divulgation à des tiers, y compris une divulgation complète
                  aux autorités compétentes.
                </p>
                <p className="my-3">
                  Nous nous réservons le droit de suspendre, de retarder ou
                  d'annuler un Instruction ou série d'instructions émises par un
                  Membre ou connivence entre les membres qui, si elle était
                  exécutée, entraînerait un prix swing de 5% ou plus de la
                  valeur d'une crypto-monnaie disponible sur le site.
                </p>
                <p className="my-3">
                  Vous n’entreprendrez aucune action, directement ou
                  indirectement conçu, ou dont on pourrait raisonnablement
                  s'attendre à ce qu'il provoque ou entraîner une
                  déstabilisation ou une manipulation du prix de Actifs virtuels
                  ou actifs numériques, disponibles sur le Site.
                </p>

                <p className="text-sm font-medium font-semibold mt-2">
                  Activités Interdites
                </p>
                <p className="my-3">
                  Vous vous engagez à ne pas utiliser les Services pour
                  effectuer des activités criminelles de quelque nature que ce
                  soit, notamment, mais sans s'y limiter, le blanchiment
                  d'argent, les opérations de jeu illégales, le financement du
                  terrorisme, le piratage malveillant ou toute activité
                  criminelle ou illégale.
                </p>
                <p className="my-3">
                  Vous vous abstiendrez d'utiliser votre Compte, le Site et les
                  Services à toute autre fin que votre usage personnel, à moins
                  que vous ne soyez une entité légale ayant ouvert un compte
                  d'entreprise auprès de nous.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Droit d'Utilisation Limité
                </p>
                <p className="my-3">
                  Sauf indication contraire, tous les matériaux de ce Site, y
                  compris, mais sans s'y limiter, le texte, les graphiques, les
                  logos, les icônes et les images (les "Matériaux"), sont la
                  propriété de {getWebsiteName()} et sont protégés par le droit
                  d'auteur, les marques de commerce et d'autres lois
                  applicables. Vous pouvez consulter, imprimer et/ou télécharger
                  une copie des Matériaux de ce Site sur un seul ordinateur
                  uniquement pour votre usage personnel, informatif et/ou non
                  commercial, à condition de respecter toutes les mentions de
                  droit d'auteur et autres avis de propriété.
                </p>
                <p className="my-3">
                  Les marques de commerce, les marques de service et les logos
                  de {getWebsiteName()} et d'autres utilisés sur ce Site (les
                  "Marques de Commerce") sont la propriété de {getWebsiteName()}{" "}
                  et de leurs propriétaires respectifs. Le logiciel, le texte,
                  les images, les graphiques, les données, les prix, les
                  transactions, les graphiques, les vidéos et l'audio utilisés
                  sur ce Site appartiennent à {getWebsiteName()}. Les Marques de
                  Commerce et les Matériaux ne doivent pas être copiés,
                  reproduits, modifiés, republiés, téléchargés, affichés,
                  transmis, grattés, collectés ou distribués sous quelque forme
                  ou par quelque moyen que ce soit, manuellement ou
                  automatiquement. L'utilisation de tels Matériaux sur tout
                  autre site web ou environnement informatique en réseau à toute
                  autre fin est strictement interdite ; une telle utilisation
                  non autorisée peut violer le droit d'auteur, les marques de
                  commerce et d'autres lois applicables et pourrait entraîner
                  des sanctions pénales ou civiles.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Récupération de Transaction</strong>
                </p>
                <p className="my-3">
                  Vous êtes entièrement responsable de toute erreur ou défaut
                  pouvant survenir dans le cadre d'une transaction entraînant
                  une perte partielle ou totale de vos Actifs Numériques et/ou
                  Actifs Virtuels. {getWebsiteName()} ne sera pas responsable
                  des dommages résultant des actions précédemment décrites. En
                  aucun cas, {getWebsiteName()} ne sera responsable de toute
                  perte, y compris, mais sans s'y limiter, les transferts entre
                  adresses, les transactions, les dépôts ou les retraits
                  résultant de vos actions ou activités incorrectes, ou de
                  transactions, de dépôts ou de retraits erronés entraînant la
                  perte de vos Actifs Numériques et/ou Actifs Virtuels.{" "}
                  {getWebsiteName()} ne vous indemnisera pas pour les pertes
                  résultant de telles actions ou activités.
                </p>
                <p className="my-3">
                  {getWebsiteName()} peut fournir, dans certaines circonstances,
                  une enquête et une éventuelle récupération des Actifs
                  Numériques et/ou Actifs Virtuels perdus en tant que service
                  payant. Par conséquent, {getWebsiteName()} peut, sur demande
                  d'un Membre, faire de son mieux pour tenter de restituer les
                  Actifs Numériques et/ou Actifs Virtuels mal dirigés au Membre.
                  Les demandes de récupération de transaction doivent être
                  envoyées via notre{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    formulaire de contact
                  </a>
                  . Après réception de la demande de récupération de
                  transaction, {getWebsiteName()} effectuera une évaluation
                  préliminaire pour chaque transaction et se réserve le droit de
                  refuser la récupération d'une transaction particulière.
                </p>
                <p className="my-3">
                  {getWebsiteName()} facturera des frais d'enquête pour une
                  telle enquête. Les frais s'élèveront à 10 % de la valeur des
                  Actifs Numériques et/ou Actifs Virtuels perdus, mais en aucun
                  cas moins de 100,00 USD/EUR. Les frais seront facturés et
                  payés par le Membre en Actifs Numériques ou Actifs Virtuels
                  faisant l'objet de la récupération de la transaction.{" "}
                  {getWebsiteName()} peut enquêter sur les transactions ayant eu
                  lieu au plus tard trente (30) jours avant la date de la
                  demande de récupération des Actifs Numériques et/ou Actifs
                  Virtuels. {getWebsiteName()} n'essaiera pas de récupérer les
                  transactions d'une valeur inférieure à 100,00 USD/EUR. En
                  raison de la spécificité et du caractère au cas par cas,{" "}
                  {getWebsiteName()} ne garantit en aucune manière le succès et
                  ne sera pas responsable si l'enquête est inefficace ou
                  infructueuse, ni {getWebsiteName()} ne peut fournir le
                  calendrier exact de telles enquêtes, mais s'efforcera de
                  fournir des commentaires dans un délai raisonnable.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Collecte et Utilisation de Vos Données</strong>
                </h2>
                <p className="my-3">
                  La confidentialité est très importante pour nous. Vous
                  trouverez tous les détails de notre Politique de
                  Confidentialité sur{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Politique de Confidentialité
                  </a>
                  . Nous vous recommandons de lire attentivement la Politique de
                  Confidentialité, afin de connaître les données que nous
                  collectons, comment nous utilisons les données et avec qui
                  nous partageons vos données.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Divers</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Réglementation Financière</strong>
                </p>
                <p className="my-3">
                  La Société est un prestataire de services d'échange entre
                  monnaies virtuelles et monnaies fiduciaires reconnues sans
                  couverture en or, enregistrée sous le numéro de certificat 133
                  en date du 22.12.2022 au Registre public des personnes
                  fournissant des services d'échange entre monnaies virtuelles
                  et monnaies fiduciaires reconnues sans couverture en or, et de
                  fournisseurs de portefeuilles offrant des services de garde
                  gérés par l'Agence nationale de recettes de Bulgarie.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>APIs</strong>
                </p>
                <p className="my-3">
                  Nous pouvons fournir l'accès à certaines parties pour accéder
                  à des données et des informations spécifiques par le biais de
                  notre API (Interface de Programmation d'Application).
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Site Web Externes</strong>
                </p>
                <p className="my-3">
                  Nous ne faisons aucune représentation quelconque concernant
                  les Sites Web Externes auxquels vous pouvez accéder via le
                  Site, y compris les sites web de tout prestataire de services
                  de garde de tiers. De temps en temps, le site web de{" "}
                  {getWebsiteName()} peut fournir des références ou des liens
                  vers des Sites Web Externes. Nous ne contrôlons pas ces Sites
                  Web Externes ou les sites tiers ni aucun contenu qui y est
                  contenu. Vous acceptez que nous ne sommes en aucun cas
                  responsables ou responsables des Sites Web Externes référencés
                  ou liés depuis le site web de {getWebsiteName()}, y compris,
                  mais sans s'y limiter, le contenu du site web, les politiques,
                  les défaillances, les promotions, les produits, les opinions,
                  les conseils, les déclarations, les prix, les activités, les
                  publicités, les services ou les actions et/ou tout dommage,
                  perte, défaillance ou problème causé, lié ou découlant de ces
                  sites. Vous assumez tous les risques associés à l'utilisation
                  de ce contenu.
                </p>
                <p className="my-3">
                  Les Sites Web Externes ont des conditions d'utilisation
                  séparées et des politiques connexes. Nous vous demandons de
                  consulter les politiques, règles, conditions et
                  réglementations de chaque site que vous visitez. Il vous
                  appartient de prendre des précautions pour vous assurer que
                  tout ce que vous choisissez d'utiliser est exempt de virus, de
                  vers, de chevaux de Troie et d'autres éléments de nature
                  destructrice.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Moyens de Communication</strong>
                </p>
                <p className="my-3">
                  Les messages électroniques non cryptés envoyés sur Internet ne
                  sont pas sécurisés, et {getWebsiteName()} n'est pas
                  responsable des dommages causés par l'envoi de messages
                  électroniques de cette manière. Nous vous suggérons d'envoyer
                  des courriels au format crypté ; vous pouvez nous envoyer des
                  courriels cryptés PGP. Les instructions et les clés pour le
                  faire sont disponibles sur demande.
                </p>
                <p className="my-3">
                  Si vous envoyez des courriels non cryptés ou non sécurisés ou
                  d'autres types de communications, nous pouvons répondre en
                  utilisant les mêmes canaux, et vous acceptez par la présente
                  les risques associés à ces communications.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Limitation de Responsabilité</strong>
                </p>
                <p className="my-3">
                  Dans la mesure permise par la loi, {getWebsiteName()} ne sera
                  pas tenue responsable de tout dommage, perte de profit, perte
                  de revenu, perte d'entreprise, perte d'opportunité, perte de
                  données, perte indirecte ou conséquente, sauf si la perte
                  subie découle de négligence grave, de tromperie délibérée ou
                  de fraude. Rien dans ces termes n'exclut ou ne limite la
                  responsabilité de l'une ou l'autre des parties en cas de
                  fraude, de décès ou de blessures corporelles causées par la
                  négligence qui ne peuvent pas être limitées ou exclues par la
                  loi. Bien que {getWebsiteName()} s'efforce de fournir des
                  informations exactes et opportunes sur le Site, le Site peut
                  ne pas toujours être entièrement précis, complet ou à jour et
                  peut contenir des erreurs. {getWebsiteName()} peut changer ou
                  mettre à jour le Site à tout moment sans préavis, et vous
                  devez donc vérifier auprès de sources indépendantes toutes les
                  informations avant de les utiliser pour prendre des décisions
                  ou des actions. Vous demeurez entièrement responsable de vos
                  décisions et de vos actions. Sous réserve de ce qui précède,
                  vous convenez également et reconnaissez que {getWebsiteName()}{" "}
                  n'a aucune responsabilité ou responsabilité en ce qui concerne
                  la garde de tout Actif Virtuel et/ou Actif Numérique.
                </p>
                <p className="my-3">
                  Sous réserve de ce qui précède, la responsabilité globale de{" "}
                  {getWebsiteName()} pour les réclamations découlant
                  d'événements liés à l'utilisation du Site et/ou des Services
                  par un seul Membre, que ce soit en contrat, en responsabilité
                  délictuelle ou autrement, ne dépassera en aucun cas les frais
                  payés par vous à {getWebsiteName()} au cours des 12 mois
                  précédant immédiatement la date de toute réclamation donnant
                  lieu à une telle responsabilité.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Indemnisation</strong>
                </p>
                <p className="my-3">
                  Dans toute la mesure permise par la loi applicable, vous
                  acceptez par la présente d'indemniser {getWebsiteName()} et
                  ses partenaires contre toute action, responsabilité, coût,
                  réclamation, perte, dommage, procédure ou dépense subie ou
                  encourue, qu'elle découle directement ou non de votre
                  utilisation du Site ou des Services ou de votre violation de
                  ces Conditions d'Utilisation.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Taxes</strong>
                </p>
                <p className="my-3">
                  Il vous incombe de déterminer si, et dans quelle mesure, des
                  taxes s'appliquent à toutes les transactions que vous
                  effectuez via les Services, et de retenir, collecter, déclarer
                  et verser les montants corrects de taxes aux autorités
                  fiscales appropriées. Votre historique de transactions est
                  disponible via votre Compte.
                </p>
                <p className="my-3">
                  En tant que client de {getWebsiteName()}, vous vous engagez à
                  être uniquement responsable de la déclaration de tout flux
                  entrant et sortant d'actifs en monnaie fiduciaire ou en crypto
                  dans le but de votre déclaration fiscale nationale et de toute
                  déclaration connexe.
                </p>
                <p className="my-3">
                  Vous assumez l'entière responsabilité de déterminer si, et
                  dans quelle mesure, des taxes s'appliquent à toutes les
                  transactions que vous effectuez via les services de{" "}
                  {getWebsiteName()}, de retenir, collecter, déclarer et verser
                  les montants corrects de taxes aux autorités fiscales
                  appropriées, et de libérer {getWebsiteName()} de toute
                  responsabilité en cas de non-respect de ces dispositions.{" "}
                  {getWebsiteName()} renonce à toute responsabilité découlant du
                  non-respect par son client de ses responsabilités en vertu des
                  présentes.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Force Majeure</strong>
                </p>
                <p className="my-3">
                  Si nous sommes incapables d'exécuter les Services décrits dans
                  les Conditions d'Utilisation en raison de facteurs
                  indépendants de notre volonté, y compris, mais sans s'y
                  limiter, un cas de force majeure, un changement de loi ou un
                  changement de politique de sanctions, nous ne serons pas
                  responsables des Services fournis en vertu de cet accord
                  pendant la période correspondant à l'événement.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Loi Applicable et Juridiction</strong>
                </h2>
                <p className="my-3">
                  Les Conditions d'Utilisation seront régies et interprétées
                  conformément à la loi de la Bulgarie. Les parties conviennent
                  de soumettre irrévocablement à la juridiction exclusive des
                  tribunaux de Bulgarie.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Définitions</strong>
                </h2>
                <p className="my-3">
                  Compte. L'arrangement contractuel selon lequel une personne a
                  accepté les Conditions d'Utilisation de {getWebsiteName()}, le
                  Barème des Frais et la{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Politique de Confidentialité
                  </a>
                  , et a reçu l'approbation pour utiliser les Services, y
                  compris l'achat et la vente d'Actifs Virtuels et d'autres
                  Actifs Numériques, ainsi que pour effectuer les transactions
                  associées.
                </p>
                <p className="my-3">
                  Acheteur(s). Membre(s) qui soumettent une offre pour acheter
                  des Actifs Virtuels ou d'autres Actifs Numériques via les
                  Services.
                </p>
                <p className="my-3">
                  Actif Numérique(s). Droit numérisé à utiliser au format
                  binaire.
                </p>
                <p className="my-3">
                  Site Web Externe(s). Sites web non détenus ou exploités par{" "}
                  {getWebsiteName()}.
                </p>
                <p className="my-3">
                  Frais. Fait référence aux frais payables à {getWebsiteName()}{" "}
                  tels que définis plus précisément dans le Barème des Frais.
                </p>
                <p className="my-3">Instructions. Désigne :</p>
                <ul className="list text--regular text--thin">
                  <li>
                    Les instructions reçues ou transmises via le Site ou reçues
                    directement de vous dans un autre support que le Site,
                    lorsque {getWebsiteName()} a expressément accepté par écrit
                    de recevoir de telles instructions de votre part autrement
                    que via le Site ;
                  </li>
                  <li>
                    Les actions que {getWebsiteName()} peut entreprendre telles
                    que décrites dans ces Conditions d'Utilisation ou dans tout
                    autre document établissant la relation juridique entre vous
                    et {getWebsiteName()}, y compris les instructions relevant
                    de notre autorité d'agence énoncée ci-dessus (y compris
                    l'instruction de créer la relation de garde juridique avec
                    les gardiens tiers dans le but d'administrer les Services
                    pour vous) ; et
                  </li>
                  <li>
                    Les instructions prescrites par la loi, les autorités de
                    régulation ou les organismes d'application de la loi.
                  </li>
                </ul>
                <p className="my-3">
                  Membre(s). Fait référence aux Acheteurs et Vendeurs ainsi qu'à
                  tout titulaire d'un Compte enregistré.
                </p>
                <p className="my-3">
                  Données Personnelles. Informations qui identifient une
                  personne, telles que le nom, l'adresse, l'adresse e-mail, les
                  informations commerciales et les coordonnées bancaires. Les
                  "Données Personnelles" n'incluent pas les données anonymisées
                  et/ou agrégées qui ne permettent pas d'identifier un
                  utilisateur spécifique.
                </p>
                <p className="my-3">
                  Prix. Le "prix par coin" auquel les Membres sont prêts à
                  acheter ou vendre des Actifs Virtuels ou d'autres Actifs
                  Numériques. Le Prix peut être exprimé dans l'une des devises
                  déposées par les Membres sur leur Compte et prises en charge
                  par les Services. Consultez notre Site pour obtenir une liste
                  complète des devises prises en charge.
                </p>
                <p className="my-3">
                  Vendeur(s). Membre(s) qui soumettent une offre pour vendre des
                  Actifs Virtuels ou d'autres Actifs Numériques via les
                  Services.
                </p>
                <p className="my-3">
                  Service(s). La plateforme technologique, les règles
                  fonctionnelles et le marché gérés par {getWebsiteName()} pour
                  permettre aux Vendeurs et aux Acheteurs d'acheter et de vendre
                  des Actifs Virtuels.
                </p>
                <p className="my-3">
                  Site. La plateforme d'échange {getWebsiteName()} est
                  accessible sur le site web{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href={"https://" + getWebsiteHost() + "/"}
                    target="_blank"
                  >
                    {getWebsiteHost()}
                  </a>
                  .
                </p>
                <p className="my-3">
                  Transaction. Comprend les éléments suivants :
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    L'accord entre l'Acheteur et le Vendeur pour échanger des
                    devises, des Actifs Virtuels ou d'autres Actifs Numériques
                    via les Services à un taux convenu ("Transaction d'Achat") ;
                  </li>
                  <li>
                    L'échange de devises fiduciaires et d'Actifs Virtuels entre
                    les Membres ("Transaction de Conversion") ;
                  </li>
                  <li>
                    L'échange d'Actifs Virtuels entre les Membres ("Transaction
                    de Transfert") ;
                  </li>
                  <li>
                    L'échange de devises fiduciaires entre les Membres
                    ("Transaction de Transfert de Devise") ; et
                  </li>
                  <li>
                    L'achat de produits accessoires ("Transaction d'Achat").
                  </li>
                </ul>
                <p className="my-3">
                  {getWebsiteName()} peut ne pas proposer tous ces types de
                  transactions à tout moment ni dans toutes les régions.
                </p>
                <p className="my-3">
                  Prix de la Transaction. Le prix total payé par l'Acheteur pour
                  chaque Transaction effectuée via les Services.
                </p>
                <p className="my-3">
                  Actif Virtuel(s). Représentation numérique de la valeur qui
                  peut être échangée ou transférée numériquement, et qui peut
                  être utilisée à des fins de paiement ou d'investissement. Les
                  Actifs Virtuels tels que les cryptomonnaies sont une forme
                  d'Actif Virtuel conçue pour fonctionner comme moyen d'échange
                  utilisant une cryptographie forte pour sécuriser les
                  transactions financières, contrôler la création d'unités
                  supplémentaires et vérifier le transfert d'actifs. Les Actifs
                  Virtuels utilisent un contrôle décentralisé par opposition aux
                  monnaies numériques centralisées et aux systèmes bancaires
                  centraux.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Contactez-Nous</strong>
                </h2>
                <p className="my-3">
                  Si vous avez des questions concernant ces Conditions
                  d'Utilisation, vos droits et obligations découlant de ces
                  Conditions et/ou de votre utilisation du Site et des Services,
                  de votre Compte ou de toute autre question, veuillez nous
                  contacter via le{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    formulaire de contact
                  </a>
                  .
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
