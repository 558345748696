import Footer from "../../components/_footer";
import Header from "../../components/_header";
import {
  getWebsiteContactEmail,
  getWebsiteHost,
  getWebsiteName,
} from "../../services/site-name";

export default function PrivacyBg() {
  document.title = getWebsiteName() + " - Политика за поверителност";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Terms of use
                </span>
              </h1>

              <br />
              <br />
              <section>
                <h2>
                  <strong>Acceptance of Terms of Use</strong>
                </h2>
                <p className="my-3">
                  By using this website (“Site”), registering for a{" "}
                  {getWebsiteName()} account (“Account”) or using any other
                  services, products, data, content or other material available
                  through the Site (“Services”) and provided by{" "}
                  {getWebsiteName()} LTD (“we”, “us”, “our”), you (“you, your,
                  or yourself”) are agreeing to accept and comply with the terms
                  and conditions of use stated below (“Terms of Use” or
                  “Terms”). You should read the entire Terms of Use carefully
                  before using this Site or any of the Services.
                </p>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>
                    IF YOU DO NOT ACCEPT THE TERMS OF USE AND CONDITIONS
                    OUTLINED BELOW, YOU MAY NOT ACCESS THIS SITE NOR USE THE
                    SERVICES.
                  </strong>
                </h2>
                <div className="divider divider--light"></div>
                <p className="my-3">
                  These Terms of Use were last updated on September 21, 2022.{" "}
                  {getWebsiteName()} reserves the right to change, add or remove
                  parts of these Terms at any time and at its sole discretion.
                  You will be notified of any changes in advance through your
                  Account. Upon such notification, it is your responsibility to
                  review the amended Terms. Your continued use of the Site and
                  the Services following the posting of a notice of changes to
                  the Terms signifies that you accept and agree to the changes,
                  and that all subsequent transactions by you will be subject to
                  the amended Terms.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>About {getWebsiteName()} and the Services</strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} Limited is registred in Bulgary.
                </p>
                <p className="my-3">
                  The Services allow registered users of the Services
                  (“Members”) to:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Buy or sell Virtual Assets and Digital Assets from or to
                    other Members in exchange for other Virtual Assets or
                    Digital Assets, or for fiat currency;
                  </li>
                  <li>
                    Enter into arrangements to store Virtual Assets and Digital
                    Assets listed on the Site with a third-party custodian;
                  </li>
                  <li>
                    Withdraw any fiat currency balance held in your Account;
                  </li>
                  <li>
                    Transfer Virtual Assets and Digital Assets to a wallet or to
                    other users of such Virtual Assets and Digital Assets
                    outside the Site; and
                  </li>
                  <li>Use Virtual Assets for purchasing goods.</li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>General Terms to the Site</strong>
                </h2>
                <p className="my-3">
                  Depending on your country of residence, incorporation or
                  registered office, you may not be able to use all the
                  functions of the Site or Services. It is your responsibility
                  to follow the rules and laws in your country of residence
                  and/or country from which you access this Site and the
                  Services. As long as you agree to and comply with these Terms
                  of Use, {getWebsiteName()} grants you the personal,
                  non-exclusive, non-transferable, non-sublicensable and limited
                  right to enter and use the Site and the Services.
                </p>
                <p className="my-3">
                  All our Services are provided without warranty of any kind,
                  either express or implied, and, in particular, without implied
                  warranties of merchantability and fitness for a particular
                  purpose. We do not guarantee that the Services as well as the
                  Site will be available 100% of the time to meet your needs. We
                  will strive to provide you with the Services as soon as
                  possible, but there are no guarantees that access will not be
                  interrupted or that there will be no delays, failures, errors,
                  omissions or loss of transmitted information.
                </p>
                <p className="my-3">
                  We will use reasonable endeavours to ensure that the Services
                  and the Site can be accessed by you in accordance with the
                  present Terms of Use. However, we may suspend the use of the
                  Site and Services for maintenance and will make reasonable
                  efforts to give you a prior notice of this. You acknowledge in
                  this context that this may not be possible in case of an
                  emergency, and accept the risks associated with the fact that
                  you may not always be able to use the Services and the Site,
                  or carry out urgent transactions using your Account.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Your {getWebsiteName()} Account</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Opening of your Account</strong>
                </p>
                <p className="my-3">
                  By opening an Account, you expressly represent and warrant:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    That you have accepted you are legally bound by these Terms
                    of Use;
                  </li>
                  <li>
                    If you are an individual, that you are at least 18 years of
                    age;
                  </li>
                  <li>
                    If you are a legal entity or acting in the name of a legal
                    entity, that you are duly authorised to act on behalf and in
                    the name of the legal entity, and bind the latter validly;
                  </li>
                  <li>
                    That you have the full capacity to accept these Terms of
                    Use, either in your own name or in the name and on behalf of
                    the legal entity you represent, and enter into transactions
                    involving Virtual Assets and other Digital Assets; and
                  </li>
                  <li>
                    That you acknowledge and agree that all information
                    provided, communicated, and exchanged with you, including
                    but not limited to any contractual information, disclaimers,
                    marketing actions and customer support, is conducted in
                    English and that you expressly renounce to any other
                    language.
                  </li>
                </ul>
                <p className="mt-3 text-base font-medium">
                  <strong>How We Verify Your Account</strong>
                </p>
                <p className="my-3">
                  The creation and use of your Account are subject to
                  verifications, as required by statutory and regulatory
                  obligations incumbent on us. You agree to provide us with the
                  information we request for the purposes of identity
                  verification, compliance with know-your-customer rules, as
                  well as detection of money laundering, terrorism financing,
                  fraud or any other financial crime. The requested information
                  may include Personal Data (please refer to our&nbsp;
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  ). By providing us with the information we request, you
                  confirm that it is true and accurate, and agree to inform us
                  in case of change concerning such information. Your Account
                  will be blocked until we are satisfied with the information
                  you have provided and determine in our sole discretion that it
                  is sufficient to validate your Account. In the meantime, you
                  will not be allowed to terminate your Account or request the
                  deletion of the Personal Data processed in the course of
                  verification operations.
                </p>
                <p className="my-3">
                  We expressly reserve the right to cancel and/or terminate
                  Accounts that have not been verified by the Member despite
                  efforts made in good faith by us to contact you to obtain such
                  verification (“Unverified Accounts”). All Unverified Accounts
                  which have been inactive for a period of 6 months or more are
                  further subject to transfer to a third-party escrow (the
                  “Unverified Escrow”), and will no longer be maintained or
                  under our legal responsibility. The administrator/trustee of
                  the Unverified Escrow shall make any and all additional
                  reasonable efforts required by law to determine and contact
                  each Unverified Account owner and, after suitable effort and
                  time has been expended, we may be required to convert the
                  residual Virtual Assets or other Digital Assets into fiat and
                  send it to a national authority responsible for the
                  safekeeping of such funds
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Funding Your Account</strong>
                </p>
                <p className="my-3">
                  If you have chosen to fund your Account via wire deposits,
                  funds from a wire deposit will be made available for trading
                  on your Account as soon as they are settled. Please note that
                  the deposit settlement times are subject to bank holidays, the
                  processes of partner banks and the processes of your bank. In
                  addition to that, deposit settlement times may be delayed
                  because of any technical issues.
                </p>
                <p className="my-3">
                  We do not accept fiat currency deposits from third-party bank
                  accounts. The name on your linked bank account must match the
                  name verified on your {getWebsiteName()} Account. If a wire
                  transfer is received from a third-party bank account, we will
                  reject and return the wire immediately.
                </p>
                <p className="my-3">
                  Your Account, once funded, does not and will not earn
                  interest.
                </p>
                <p className="my-3">
                  Please note that the deposit settlement times are subject to
                  bank holidays, the processes of partner banks and the
                  processes of your bank. In addition to that, deposit
                  settlement times may be delayed because of any technical
                  issues.
                </p>
                <p className="my-3">
                  We reserve the right to increase or decrease your daily,
                  monthly and overall limits, or make them temporarily or
                  permanently unavailable. This may be done without notice and
                  at our sole discretion.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Refund Policy</strong>
                </p>
                <p className="my-3">
                  All sales and purchases of Virtual Assets and other Digital
                  Assets done with any payment method are final. We do not
                  accept any returns or provide refunds for your purchase of
                  Virtual Assets or any other Digital Assets from{" "}
                  {getWebsiteName()}, except for the cases provided in these
                  Terms of Use.
                </p>
                <p className="my-3">
                  In the event the exchange of fiat currency to Virtual Assets
                  or other Digital Assets is not processed because of any
                  technical issues or high price volatility during the card
                  purchase procedure, {getWebsiteName()} will credit the
                  Member’s account with fiat currency.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Maintaining Your Account</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Keep Your Information Up-to-Date
                </p>
                <p className="my-3">
                  We are vigilant in maintaining the security of our Site and
                  the Services. By registering with us, you agree to provide{" "}
                  {getWebsiteName()} with current, accurate and complete
                  information about yourself, including, for legal entities,
                  information on directors, key personnel and beneficial owners,
                  as well as company resolution to open the Account, as prompted
                  by the registration process, and to keep such information
                  updated. If you are an individual, you further agree that you
                  will not use any Account other than your own or access the
                  Account of any other Member at any time or assist others in
                  obtaining unauthorised access.
                </p>
                <p className="my-3">
                  The creation or use of Accounts without obtaining our prior
                  express permission will result in the immediate suspension of
                  all said Accounts, as well as all pending purchase/sale
                  offers. Any attempt to do so or to assist others (Members or
                  otherwise), or the distribution of instructions, software or
                  tools for that purpose, will result in the Accounts of such
                  Members being terminated. Termination is not the exclusive
                  remedy for such a violation, and we may elect to take further
                  action against you.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Maintain the Confidentiality of Your Account Information
                </p>
                <p className="my-3">
                  You are also responsible for maintaining the confidentiality
                  of your Account information, including your password,
                  safeguarding your own Virtual Assets and Digital Assets, and
                  all activity including Transactions that are posted to your
                  Account (including all Instructions to {getWebsiteName()}).
                  Any actions on the Site, transactions, orders, Instructions
                  and operations initiated from your Account or using your
                  password are (1) considered to have been made by you and (2)
                  irrevocable once validated using your password or made through
                  your Account. If there is suspicious activity related to your
                  Account, we may, but are not obligated to, request additional
                  information from you, including authenticating documents, and
                  freeze any transactions pending our review. You are obligated
                  to comply with these security requests or accept the
                  termination of your Account. You are required to notify{" "}
                  {getWebsiteName()} immediately of any unauthorised use of your
                  Account or password, or any other breach of security, using
                  our{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    contact form
                  </a>
                  . Any Member who violates these rules may be terminated, and
                  thereafter held liable for losses incurred by{" "}
                  {getWebsiteName()} or any user of the Site and Services.
                </p>
                <p className="my-3">
                  You acknowledge that you will only credit your{" "}
                  {getWebsiteName()} Account from a bank account that is in your
                  own name and that you will instruct payments from your{" "}
                  {getWebsiteName()} Account only to a bank account that is in
                  your own name.
                </p>
                <p className="my-3">
                  We reserve the right to cancel transfers which are made by or
                  sent to third parties.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Keep Your Virtual and Digital Assets Safe
                </p>
                <p className="my-3">
                  {getWebsiteName()}’s role is limited to providing you with a
                  platform via the Services under which we act solely as a
                  service provider, as your agent and in accordance with your
                  Instructions for the management and transfer of Virtual Assets
                  and other Digital Assets.
                </p>
                <p className="my-3">
                  In order for us to manage the Site, provide the Services and
                  create a direct relationship between you and the third-party
                  custodian(s) to hold the relevant Virtual Assets or other
                  Digital Assets as custodian on your behalf, you appoint{" "}
                  {getWebsiteName()} as your agent with a perpetual and
                  irrevocable (other than in the event of Account termination)
                  agency authority to act as your agent to:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Create a direct legal-custodial relationship between you and
                    a third-party custodian(s) for the custody of your Virtual
                    Assets or other Digital Assets, as well as appointing
                    authorised persons within {getWebsiteName()} to instruct
                    such third-party custodian(s) on your behalf;
                  </li>
                  <li>
                    Access and/or transfer Virtual Assets or other Digital
                    Assets as required for the operation of the Site and
                    provision of the Services, including:
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      Storing and keeping confidential private keys in respect
                      of Virtual Assets or other Digital Assets;
                    </li>
                    <li>
                      Transferring cryptocurrencies to third-party custodian(s)
                      (who are entitled in turn to appoint sub-custodians and
                      provide the custodian services to you through such
                      sub-custodians or other nominees or agents); and
                    </li>
                    <li>
                      Moving cryptocurrencies between hot and cold wallets held
                      by these custodian(s) to allow sufficient hot wallet
                      liquidity to support trades on the Site whilst at the same
                      time providing the additional security of cold wallet
                      storage;
                    </li>
                  </ul>
                  <li>
                    Share your information with third-party custodian-service
                    provider(s) so that they can be engaged to provide custodian
                    services to you (see below);
                  </li>
                  <li>
                    Transfer Virtual Assets and Digital Assets to a wallet or to
                    other users of such Virtual Assets and Digital Assets
                    outside the Site;
                  </li>
                  <li>
                    Undertake the following events that act, or could act as
                    restrictions, or impact your rights:
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      &nbsp;Freezing of Ripple accounts in accordance with
                      Ripple Labs policy;
                    </li>
                    <li>
                      &nbsp;Termination of our relationship with you and/or your
                      Account;
                    </li>
                    <li>
                      &nbsp;Suspension of our relationship with you and/or your
                      Account;
                    </li>
                    <li>
                      &nbsp;Prohibiting wash trading and other activities;
                    </li>
                    <li>&nbsp;Requiring minimum trade sizes;</li>
                    <li>&nbsp;Blocking Accounts in certain circumstances;</li>
                    <li>
                      &nbsp;Applying service downtime and unavailability
                      restrictions;
                    </li>
                    <li>
                      &nbsp;Complying with enforcement-agency orders or
                      regulatory actions;
                    </li>
                    <li>
                      &nbsp;Offsetting any outstanding obligation you have to{" "}
                      {getWebsiteName()}, which may include the sale of any
                      Virtual Assets or other Digital Assets in order to cover
                      the outstanding obligation you have; and
                    </li>
                  </ul>
                  <li>
                    Otherwise act only on your instructions in respect of any
                    Virtual Assets or other Digital Assets, including in respect
                    of:
                  </li>
                  <ul className="list text--regular text--thin">
                    <li>
                      &nbsp;Any buying or selling of Virtual Assets or other
                      Digital Assets;
                    </li>
                    <li>
                      &nbsp;The accessing or transferring of Virtual Assets or
                      other Digital Assets between wallets; and/or
                    </li>
                    <li>
                      &nbsp;Any other purpose or action as instructed by you.
                    </li>
                  </ul>
                </ul>
                <p className="my-3">
                  By agreeing to these Terms of Use you acknowledge and agree
                  that in doing so, you instruct us on an irrevocable ongoing
                  basis to undertake all of the above-mentioned actions on your
                  behalf. You cannot revoke this ongoing Instruction by closing
                  your Account, in which case it will lapse only when your
                  Account is closed.
                </p>
                <p className="my-3">
                  As a result of this limited agency relationship, we do not,
                  and you agree that we do not, have or acquire any rights,
                  title or interest in any Virtual Assets or other Digital
                  Assets that are held on your behalf by the third-party
                  custodian(s).
                </p>
                <p className="my-3">
                  We are not an intermediary, do not acquire any rights, title
                  or interest in, and do not assume and have no rights or
                  control over any Virtual Assets or other Digital Assets or any
                  other Member assets other than in respect of actions taken in
                  accordance with our agency authority and your Instructions. As
                  a result, we will not transfer or sell any Virtual Assets or
                  other Digital Assets other than in accordance with your
                  Instructions (which includes any instructions mandated by law,
                  regulatory authority or enforcement agencies).
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Closing Your Account</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  You may close your Account at any time.
                </p>
                <p className="my-3">
                  You may terminate this agreement with {getWebsiteName()}
                  and close your Account at any time by contacting us{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    here
                  </a>
                  , following settlement of any pending Transactions.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  We may close or suspend or freeze your Account on notice.
                </p>
                <p className="my-3">
                  We may, by giving notice, at our sole discretion:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Limit, suspend or terminate your access to the Service, the
                    Site and/or to your Account;
                  </li>
                  <li>
                    Prohibit access to the Site and its content, services and
                    tools;
                  </li>
                  <li>Delay or remove hosted content; and</li>
                  <li>
                    Take technical and legal steps to keep Members off the Site
                    if we suspect that they are creating problems or possible
                    legal liabilities, infringing the intellectual property
                    rights of third parties, or acting inconsistently with the
                    letter or spirit of these Terms.
                  </li>
                </ul>
                <p className="my-3">
                  Additionally, we may, in appropriate circumstances and at our
                  discretion, suspend or terminate Accounts of Members for any
                  reason, including without limitation:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Attempts to gain unauthorised access to the Site or another
                    Member’s account or providing assistance to others
                    attempting to do so;
                  </li>
                  <li>
                    Overcoming software security features limiting use of or
                    protecting any content;
                  </li>
                  <li>
                    Usage of the Service to perform illegal activities such as
                    money laundering, illegal gambling operations, financing
                    terrorism, or other criminal activities;
                  </li>
                  <li>Violations of these Terms of Use;</li>
                  <li>
                    A failure to pay or a fraudulent payment for Transactions;
                  </li>
                  <li>Unexpected operational difficulties; or</li>
                  <li>
                    Upon the request of law enforcement or other government
                    agencies.
                  </li>
                </ul>
                <p className="my-3">
                  The suspension or escrow of an Account shall not affect the
                  payment of Fees due for past Transactions.
                </p>
                <p className="my-3">
                  Upon termination or suspension, you shall send details of a
                  valid bank account to allow for the transfer of any currencies
                  credited to their account. This bank account must be held by
                  you. Virtual Assets or other Digital Assets may be transferred
                  to a valid bank account only after conversion into a fiat
                  currency. We shall transfer the currencies as soon as possible
                  following your request and within the time frames we
                  specified.
                </p>
                <p className="my-3">
                  We will arrange to send you the credit balance of your
                  Account; however, in certain circumstances a number of
                  intermediaries may be involved in an international payment and
                  these or the beneficiary bank may deduct charges. We will make
                  all reasonable efforts to ensure that such charges are
                  disclosed to you prior to sending your payment; however, where
                  they cannot be avoided, you acknowledge that these charges
                  cannot always be calculated in advance, and that you agree to
                  be responsible for such charges.
                </p>
                <p className="my-3">
                  Upon Account closure, any amount less than 25 USD/EUR in value
                  will not be returned.
                </p>
                <p className="my-3">
                  We reserve the right to not convert Virtual Assets or other
                  Digital Assets into fiat and to return them to a Wallet that
                  belongs to the Member.
                </p>
                <p className="my-3">
                  We similarly reserve the right to freeze Ripple accounts in
                  accordance with the Ripple Labs policy, which took effect on
                  15 September 2014. Please see the explanation provided by
                  Ripple Labs below:
                </p>
                <p className="my-3">
                  <em>
                    The freeze protocol extension gives gateways the ability to
                    1) globally freeze all their issued funds or 2) freeze funds
                    issued to a specific user. Frozen funds may only be sent
                    back to the issuing gateway. The global freeze feature
                    allows a gateway to freeze all balances it issues. The
                    gateway may still issue payments. Accounts holding frozen
                    balances may return the funds to the gateway. This feature
                    is useful for migrating users from one account to another
                    and to safeguard users in the event that the gateway account
                    is compromised. The individual freeze is primarily intended
                    for compliance with regulatory requirements, which may vary
                    from one jurisdiction to another. It also allows gateways to
                    freeze individual accounts issuances in order to investigate
                    suspicious activity. These features allow gateways to better
                    operate in compliance with laws and regulations.
                  </em>
                </p>
                <p className="my-3">
                  For the avoidance of doubt, any action taken by{" "}
                  {getWebsiteName()} to suspend, delay, cancel or freeze any
                  instructions and/or to suspend your Account will not affect
                  your rights, title or interest in respect of your Virtual
                  Assets and other Digital Assets which are held by a
                  third-party custodian(s) on your behalf, but may affect{" "}
                  {getWebsiteName()}’s ability to execute your Instructions,
                  which you hereby acknowledge and agree.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Trading with {getWebsiteName()}</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Risks</strong>
                </p>
                <p className="my-3">
                  The trading of goods and products, real or virtual, as well as
                  virtual currencies involves significant risk. Prices can and
                  do fluctuate on any given day. Such price fluctuations may
                  increase or decrease the value of your assets at any given
                  moment. Any currency – virtual or not – may be subject to
                  large swings in value and may even become worthless. There is
                  an inherent risk that losses will occur as a result of buying,
                  selling or trading anything on a market.
                </p>
                <p className="my-3">
                  Virtual Asset and Digital Asset trading also has special risks
                  not generally shared with official currencies, goods or
                  commodities in a market. Unlike most currencies, which are
                  backed by governments or other legal entities, or by
                  commodities such as gold or silver, Virtual Assets and Digital
                  Assets are a unique type of asset, backed by technology and
                  trust. There is no central bank that can issue more
                  cryptocurrency or take corrective measures to protect the
                  value of Virtual Assets and Digital Assets in a crisis.
                </p>
                <p className="my-3">
                  Instead, Virtual Assets and Digital Assets are an as-yet
                  autonomous and largely unregulated global system of
                  cryptocurrency firms and individuals. Traders put their trust
                  in a digital, decentralised and partially anonymous system
                  that relies on peer-to-peer networking and cryptography to
                  maintain its integrity.
                </p>
                <p className="my-3">
                  The trading of Virtual Assets and Digital Assets is often
                  susceptible to irrational (or rational) bubbles or loss of
                  confidence, which could collapse demand relative to supply.
                  For example, confidence in Virtual Assets and Digital Assets
                  might collapse because of unexpected changes imposed by
                  software developers or others, a government crackdown, the
                  creation of superior competing alternative currencies, or a
                  deflationary or inflationary spiral. Confidence might also
                  collapse because of technical problems: if the anonymity of
                  the system is compromised, if money is lost or stolen, or if
                  hackers or governments are able to prevent any transactions
                  from settling.
                </p>
                <p className="my-3">
                  There may be additional risks that we have not foreseen or
                  identified in our Terms of Use.
                </p>
                <p className="my-3">
                  You should carefully assess whether your financial situation
                  and tolerance for risk is suitable for buying, selling or
                  trading Virtual Assets or Digital Assets.
                </p>
                <p className="my-3">
                  We use our banking providers in order to facilitate the
                  receipt of fiat currency from Members and payments to other
                  Members. Our banking providers DO NOT transfer, exchange or
                  provide any services in connection with Virtual Assets or
                  Digital Assets. Your fiat currency will be held in a bank
                  account in the name of {getWebsiteName()} together with other
                  Members' fiat currency. Where possible in a relevant
                  jurisdiction which recognises the concept of a client account,
                  we will notify the relevant banking provider that the bank
                  account is a client account in which we hold fiat currency on
                  behalf of Members. You will, in any event, remain the
                  beneficial owner of the fiat currency that we hold on your
                  behalf. We will maintain detailed records of all fiat currency
                  held on behalf of Members. Whilst {getWebsiteName()}
                  uses reasonable care in the appointment of its banking
                  providers, in the event of a banking provider becoming
                  insolvent or entering into an insolvency process in a relevant
                  jurisdiction, {getWebsiteName()} may have only an unsecured
                  claim against the banking provider, and Members’ fiat currency
                  balances may be at risk subject to any protections provided at
                  law in the relevant jurisdiction.
                </p>
                <p className="my-3">
                  We engage with third-party custodians in order to hold Virtual
                  Assets and Digital Assets on your behalf. Your Virtual Assets
                  and Digital Assets will be held on your behalf in a wallet in
                  the name of {getWebsiteName()} together with other Members’
                  Virtual Assets and/or Digital Assets. They may also from time
                  to time be held in the wallet together with {getWebsiteName()}
                  ’s Virtual Assets and/or Digital Assets which reflect{" "}
                  {getWebsiteName()}’s fees, but which are recorded on{" "}
                  {getWebsiteName()}’s ledger as belonging to
                  {getWebsiteName()}, and which you instruct {getWebsiteName()}{" "}
                  to collect by withdrawing them from the wallet. We will
                  maintain detailed records of all Virtual Assets and Digital
                  Assets which Members hold with third-party custodians
                  appointed by us.
                </p>
                <p className="my-3">
                  As set out above, {getWebsiteName()} will act only on your
                  Instructions in respect of Virtual Assets and Digital Assets,
                  and does not otherwise acquire any right, title or interest in
                  them. Whilst {getWebsiteName()} uses reasonable care in the
                  appointment of third-party custodians, in the event of a
                  third-party custodian becoming insolvent or entering into an
                  insolvency process in a relevant jurisdiction, you may have
                  only an unsecured claim against the third-party custodian, and
                  Members' Virtual Assets or Digital Assets may be at risk
                  subject to any protections provided at law in the relevant
                  jurisdiction.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Risks related to Fraud and Scams.
                </p>
                <p className="my-3">
                  Before you engage in using our Services and by confirming
                  these Terms of Use, you agree and acknowledge that:
                </p>
                <ol className="list text--regular text--thin" type="a">
                  <li>
                    {getWebsiteName()} will not be responsible for any loss or
                    damage suffered by you, due to any illicit or fraudulent
                    activity of third parties - you are solely responsible for
                    your behavior and usage of your Account;
                  </li>
                  <li>
                    {getWebsiteName()} does not promote or operate giveaways -
                    official promotions and referral programs are only
                    communicated through {getWebsiteName()}-owned channels, e.g.
                    the Site;
                  </li>
                  <li>
                    {getWebsiteName()} only communicates with you through the @
                    {getWebsiteContactEmail().split("@")[1]} address;
                  </li>
                  <li>
                    {getWebsiteName()} will never call you unexpectedly and
                    without prior notice, regardless of cause or reason;
                  </li>
                  <li>
                    {getWebsiteName()} will never ask you to disclose any of
                    your sensitive authentication credentials, regardless of
                    cause or reason, and will never demand remote access to your
                    device or advise you to change your security settings;
                  </li>
                  <li>
                    Your Account credentials should be solely used by you and
                    you shall not disclose your login credentials to any third
                    person or entity. You will ensure that the email and phone
                    number which you used for registering a {getWebsiteName()}{" "}
                    Account will be exclusively operated and kept in private by
                    you;
                  </li>
                  <li>
                    You will set the 2-factor authentication solely on the
                    devices which are possessed and controlled exclusively by
                    you and you will not permit anybody to access or use the
                    devices you use for the 2-factor authentication;
                  </li>
                  <li>
                    Any attempt of providing instructions to any person (Members
                    or third party), or distribution of instructions, software
                    or tools, related to onboarding process, operating of the
                    Account and security measures, regardless of purpose, may
                    result in the Accounts of both the defaulting and affected
                    Members being terminated, at the sole discretion of{" "}
                    {getWebsiteName()}.
                  </li>
                </ol>

                <p className="mt-3 text-base font-medium">
                  <strong>Acceptable Use of Our Services</strong>
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Wash Trading
                </p>
                <p className="my-3">
                  We do not allow you to cross trade, either alone or in
                  collusion with others or to place an order, which would result
                  in a self-execution—i.e., where the same trader or group of
                  traders would act as both the maker and taker for the trade.
                </p>
                <p className="my-3">
                  If two orders of opposing sides would result in a crossed
                  trade, both orders are subject to specific measures, ranging
                  from scrutiny, cancellation, suspension or prohibition to
                  trade on the Site. If two orders of the same quantity would
                  result in self-execution, both orders are subject to specific
                  measures, ranging from scrutiny, cancellation, suspension or
                  prohibition to trade on the Site.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Market Manipulation
                </p>
                <p className="my-3">
                  You are prohibited from engaging or attempting to engage in
                  market manipulation. This prohibition applies to any kind of
                  activity that results in or has the purpose of:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    &nbsp;Artificially controlling or manipulating the price or
                    trading volume of any of the virtual currencies listed on
                    the Site; and
                  </li>
                  <li>
                    &nbsp;Manipulating of a benchmark, including, but not
                    limited to, the transmission of false or misleading inputs,
                    or any other action that manipulates the calculation of a
                    benchmark.
                  </li>
                </ul>
                <p className="my-3">
                  Market manipulation will trigger specific measures, ranging
                  from scrutiny, cancellation of orders, suspension or
                  prohibition to trade on the Site and disclosure to third
                  parties including full disclosure to competent authorities.
                </p>
                <p className="my-3">
                  We reserve the right to suspend, delay or cancel an
                  Instruction or series of Instructions issued by a Member or
                  colluding Members which if executed would result in a price
                  swing of 5% or more of the value of a cryptocurrency available
                  on the Site.
                </p>
                <p className="my-3">
                  You will not take, directly or indirectly, any action
                  designed, or that might reasonably be expected, to cause or
                  result in destabilization or manipulation of the price of
                  Virtual Assets or Digital Assets, which are available on the
                  Site.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Prohibited Activities
                </p>
                <p className="my-3">
                  You agree not to use the Services to perform criminal activity
                  of any sort, including but not limited to, money laundering,
                  illegal gambling operations, terrorist financing, malicious
                  hacking or any criminal or illegal activity.
                </p>
                <p className="my-3">
                  You will refrain from using your Account, the Site and
                  Services for any other use than your personal use, unless you
                  are a legal entity that has opened a corporate account with
                  us.
                </p>
                <p className="text-sm font-medium font-semibold mt-2">
                  Limited Right of Use
                </p>
                <p className="my-3">
                  Unless otherwise specified, all materials on this Site,
                  including, but not limited, to text, graphics, logos, icons
                  and images (“Materials”), are the property of{" "}
                  {getWebsiteName()} and are protected by copyright, trademark
                  and other applicable laws. You may view, print and/or download
                  a copy of the Materials from this Site on any single computer
                  solely for your personal, informational and/or non-commercial
                  use, provided you comply with all copyright and other
                  proprietary notices.
                </p>
                <p className="my-3">
                  The trademarks, service marks and logos of {getWebsiteName()}{" "}
                  and others used on this Site (“Trademarks”) are the property
                  of {getWebsiteName()}
                  and their respective owners. The software, text, images,
                  graphics, data, prices, trades, charts, graphs, video and
                  audio used on this Site belong to {getWebsiteName()}. The
                  Trademarks and Material should not be copied, reproduced,
                  modified, republished, uploaded, posted, transmitted, scraped,
                  collected or distributed in any form or by any means, whether
                  manual or automated. The use of any such Materials on any
                  other website or networked computer environment for any other
                  purpose is strictly prohibited; any such unauthorised use may
                  violate copyright, trademark and other applicable laws and
                  could result in criminal or civil penalties.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Transaction Recovery</strong>
                </p>
                <p className="my-3">
                  You are fully responsible for any mistakes, errors or defects
                  which may arise in the course of any transaction that leads to
                  partial or complete loss of your Digital Assets and/or Virtual
                  Assets. {getWebsiteName()} shall not be liable for any damages
                  resulting from any actions previously described.{" "}
                  {getWebsiteName()} shall in no case be liable for any loss,
                  including, but not limited to, transfers between addresses,
                  transactions, deposits or withdrawals resulting from your
                  improper actions or activities, or erroneous transactions,
                  deposits or withdrawals resulting in your Digital Assets
                  and/or Virtual Assets being lost. {getWebsiteName()}
                  shall not compensate you for any losses resulting from such
                  actions or activities.
                </p>
                <p className="my-3">
                  {getWebsiteName()} may provide, in certain circumstances, an
                  investigation and possible recovery of the lost Digital Assets
                  and/or Virtual Assets as a payable service. Therefore,{" "}
                  {getWebsiteName()} may, upon the request of a Member, utilize
                  its best efforts to attempt to return misdirected Digital
                  Assets and/or Virtual Assets to the Member. Requests for
                  transaction recovery shall be sent to our{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    contact form
                  </a>
                  . After receiving the transaction recovery request,{" "}
                  {getWebsiteName()} will make a preliminary assessment for each
                  transaction and reserves the right to refuse the recovery of a
                  particular transaction.
                </p>
                <p className="my-3">
                  {getWebsiteName()} will charge an investigation fee for any
                  such investigation. The fee shall amount to 10% of the value
                  of lost Digital Assets and/or Virtual Assets, but in no case
                  less than 100.00 USD/EUR. The fee will be charged and shall be
                  paid by the Member in Digital Assets or Virtual Assets which
                  are subject to the transaction recovery. {getWebsiteName()}{" "}
                  may investigate transactions that occurred not more than
                  thirty (30) days prior to the date of the request for the
                  recovery of Digital Assets and/or Virtual Assets.{" "}
                  {getWebsiteName()} will not attempt to recover transactions
                  with the value below 100.00 USD/EUR. Due to the specifics and
                  case-by-case nature, {getWebsiteName()}
                  does not, in any way, guarantee success and shall not be
                  liable if the investigation is ineffective or unsuccessful,
                  nor can {getWebsiteName()} provide the exact timing for such
                  investigations, but will strive to provide feedback within a
                  reasonable time.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Collection and Use of Your Data</strong>
                </h2>
                <p className="my-3">
                  Privacy is very important to us. Full details of our Privacy
                  Policy can be found at&nbsp;
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  . We recommend that you read the Privacy Policy carefully, so
                  that you know the data that we collect, how we use the data
                  and who we share your data with.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Miscellaneous</strong>
                </h2>
                <p className="mt-3 text-base font-medium">
                  <strong>Financial Regulation</strong>
                </p>
                <p className="my-3">
                  The Company is a provider of exchange services between virtual
                  currencies and recognized fiat currencies without golden
                  cover, registered with Certificate No. 133 dated 22.12.2022 in
                  the Public Register of persons providing, exchange services
                  between virtual currencies and recognized fiat currencies
                  without golden cover, and of wallet providers offering custody
                  services maintained by the Bulgarian National Revenue Agency.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>APIs</strong>
                </p>
                <p className="my-3">
                  We may provide access to certain parties to access specific
                  data and information through our API (Application Programming
                  Interface).
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>External Websites</strong>
                </p>
                <p className="my-3">
                  We make no representations whatsoever about any External
                  Websites you may access through the Site including the
                  websites of any third-party custodian service providers.
                  Occasionally, the {getWebsiteName()} website may provide
                  references or links to External Websites. We do not control
                  these External Websites or third-party sites or any of the
                  content contained therein. You agree that we are in no way
                  responsible or liable for the External Websites referenced or
                  linked from the {getWebsiteName()} website, including, but not
                  limited to, website content, policies, failures, promotions,
                  products, opinions, advice, statements, prices, activities,
                  advertisements, services or actions and/or any damages,
                  losses, failures or problems caused by, related to or arising
                  from those sites. You shall bear all risks associated with the
                  use of such content.
                </p>
                <p className="my-3">
                  External Websites have separate and independent terms of use
                  and related policies. We request that you review the policies,
                  rules, terms and regulations of each site that you visit. It
                  is up to you to take precautions to ensure that whatever you
                  select for your use is free of items such as viruses, worms,
                  Trojan horses and other items of a destructive nature.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Means of Communication</strong>
                </p>
                <p className="my-3">
                  Unencrypted email messages sent over the Internet are not
                  secure, and {getWebsiteName()} is not responsible for any
                  damages incurred by the result of sending email messages in
                  this way. We suggest sending email in encrypted formats; you
                  are welcome to send PGP encrypted emails to us. The
                  instructions and keys to do so are available upon request.
                </p>
                <p className="my-3">
                  If you send unencrypted or unsecured email or other types of
                  communications to us, we may respond using the same channels,
                  and you hereby accept the risks associated therewith.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Limitation of Liability</strong>
                </p>
                <p className="my-3">
                  To the extent permitted by law, {getWebsiteName()} will not be
                  held liable for any damages, loss of profit, loss of revenue,
                  loss of business, loss of opportunity, loss of data, indirect
                  or consequential loss unless the loss suffered arose from
                  gross negligence, wilful deceit or fraud. Nothing in these
                  terms excludes or limits the liability of either party for
                  fraud, death or personal injury caused by negligence which may
                  not be limited or excluded by law. Although {getWebsiteName()}{" "}
                  endeavours to provide accurate and timely information on the
                  Site, the Site may not always be entirely accurate, complete
                  or current and may include errors. {getWebsiteName()}
                  may change or update the Site at any time without notice, and
                  you should accordingly verify with independent sources all
                  information before relying on it to take decisions or actions.
                  You remain entirely responsible for your decisions and
                  actions. Subject to the above, you also agree and acknowledge
                  that {getWebsiteName()} has no liability or responsibility in
                  respect of the custody of any Virtual Assets and/or Digital
                  Assets.
                </p>
                <p className="my-3">
                  Subject to the foregoing, {getWebsiteName()}’s aggregate
                  liability for claims based on events arising out of or in
                  connection with any single Member’s use of the Site and/or
                  Services, whether in contract, tort or otherwise, shall in no
                  circumstances exceed the fees paid by you to{" "}
                  {getWebsiteName()} during the 12 months immediately preceding
                  the date of any claim giving rise to such liability.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Indemnity</strong>
                </p>
                <p className="my-3">
                  To the full extent permitted by applicable law, you hereby
                  agree to indemnify {getWebsiteName()} and its partners against
                  any action, liability, cost, claim, loss, damage, proceeding
                  or expense suffered or incurred if directly or not directly
                  arising from your use of the Site or the Services or from your
                  violation of these Terms of Use.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Taxes</strong>
                </p>
                <p className="my-3">
                  It is your responsibility to determine whether, and to what
                  extent, any taxes apply to any transactions you conduct
                  through the Services, and to withhold, collect, report and
                  remit the correct amounts of taxes to the appropriate tax
                  authorities. Your transaction history is available through
                  your Account.
                </p>
                <p className="my-3">
                  As Client of {getWebsiteName()}, you duly undertake to be
                  solely responsible for declaration of any inflow and outflow
                  of fiat or crypto assets for the purpose of your domestic tax
                  returns and any related declarations.
                </p>
                <p className="my-3">
                  You assume full responsibility to determine whether, and to
                  what extent, any taxes apply to any transactions you conduct
                  through the {getWebsiteName()} services, to withhold, collect,
                  report and remit the correct amounts of taxes to the
                  appropriate tax authorities and release {getWebsiteName()}{" "}
                  from any liability in the event of non-compliance with these
                  provisions. {getWebsiteName()} waives any responsibility
                  arising from its client's failure to meet its responsibilities
                  hereunder.
                </p>
                <p className="mt-3 text-base font-medium">
                  <strong>Force Majeure</strong>
                </p>
                <p className="my-3">
                  If we are unable to perform the Services outlined in the Terms
                  of Use due to factors beyond our control including but not
                  limited to an event of Force Majeure, change of law or change
                  in sanctions policy, we shall not be liable for the Services
                  provided under this agreement during the time period
                  coincident with the event.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Governing Law and Jurisdiction</strong>
                </h2>
                <p className="my-3">
                  The Terms of Use shall be governed and construed in accordance
                  with the law of Bulgary. The parties agree to irrevocably
                  submit to the exclusive jurisdiction of the courts of Bulgary.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Definitions</strong>
                </h2>
                <p className="my-3">
                  Account. The contractual arrangement wherein an individual has
                  accepted the {getWebsiteName()} Terms of Use, Fee Schedule
                  and&nbsp;
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/legal/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  , and received approval to use the Services, including the
                  purchase and sale of Virtual Assets and other Digital Assets,
                  and to perform associated Transactions.
                </p>
                <p className="my-3">
                  Buyer(s). Member(s) that submit an offer to buy Virtual Assets
                  or other Digital Assets through the Services.
                </p>
                <p className="my-3">
                  Digital Asset(s). Digitalised right to use in a binary format.
                </p>
                <p className="my-3">
                  External Website(s). Websites not owned or operated by{" "}
                  {getWebsiteName()}.
                </p>
                <p className="my-3">
                  Fee(s). Refers to the fee which is payable to{" "}
                  {getWebsiteName()} as further defined in the Fee Schedule.
                </p>
                <p className="my-3">Instructions. Means:</p>
                <ul className="list text--regular text--thin">
                  <li>
                    Instructions received or directed via the Site or as
                    received directly from you in any medium other than the
                    Site, where {getWebsiteName()} has expressly agreed in
                    writing to accept such instructions from you other than via
                    the Site;
                  </li>
                  <li>
                    Acts {getWebsiteName()} may take as set out in these Terms
                    of Use or in any other form of documentation establishing
                    the legal relationship between you and {getWebsiteName()},
                    including instructions within the remit of our agency
                    authority set out above (including the Instruction to create
                    the legal custodial relationship with the third-party
                    custodian(s) for the purpose of administering the Services
                    for you); and
                  </li>
                  <li>
                    Instructions mandated by law, regulatory authority or
                    enforcement agencies.
                  </li>
                </ul>
                <p className="my-3">
                  Member(s). Refers to Buyers and Sellers as well as any
                  registered holders of an Account.
                </p>
                <p className="my-3">
                  Personal Data. Information that identifies an individual, such
                  as name, address, email address, trading information and
                  banking details. “Personal Data” does not include anonymised
                  and/or aggregated data that does not identify a specific user.
                </p>
                <p className="my-3">
                  Price. The “price per coin” for which Members are willing to
                  buy or sell Virtual Assets or other Digital Assets. The Price
                  may be expressed in any of the currencies deposited by Members
                  in their Account and supported by the Services. See our Site
                  for a full list of currencies.
                </p>
                <p className="my-3">
                  Seller(s). Member(s) that submit an offer to sell Virtual
                  Assets or other Digital Assets through the Services.
                </p>
                <p className="my-3">
                  Service(s). The technological platform, functional rules and
                  market managed by {getWebsiteName()} to permit Sellers and
                  Buyers to purchase and sell Virtual Assets.
                </p>
                <p className="my-3">
                  Site. {getWebsiteName()} exchange platform is made available
                  on website&nbsp;
                  <a
                    className="font-medium text-gray-700 underline"
                    href={"https://" + getWebsiteHost() + "/"}
                    target="_blank"
                  >
                    {getWebsiteHost()}
                  </a>
                  .
                </p>
                <p className="my-3">Transaction. Includes the following:</p>
                <ul className="list text--regular text--thin">
                  <li>
                    The agreement between the Buyer and the Seller to exchange
                    currencies, Virtual Assets or other Digital Assets through
                    the Services for currencies at a commonly agreed rate
                    (“Purchase Transaction”);
                  </li>
                  <li>
                    The exchange of fiat currencies and Virtual Assets between
                    Members (“Conversion Transaction”);
                  </li>
                  <li>
                    The exchange of Virtual Assets between Members (“Transfer
                    Transaction”);
                  </li>
                  <li>
                    The exchange of fiat currencies among Members (“Currency
                    Transfer Transaction”); and
                  </li>
                  <li>
                    The purchase of ancillary products (“Purchase Transaction”).
                  </li>
                </ul>
                <p className="my-3">
                  {getWebsiteName()} may not offer all these transaction types
                  at this time or at all locations.
                </p>
                <p className="my-3">
                  Transaction Price. The total price paid by the Buyer for each
                  Transaction performed through the Services.
                </p>
                <p className="my-3">
                  Virtual Asset(s). Digital representation of value that can be
                  digitally traded, or transferred, and can be used for payment
                  or investment purposes. Virtual Assets such as
                  cryptocurrencies are a form of Virtual Asset designed to work
                  as a medium of exchange that uses strong cryptography to
                  secure financial transactions, control the creation of
                  additional units and verify the transfer of assets. Virtual
                  Assets use decentralised control as opposed to centralised
                  digital currencies and central banking systems.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>Contact Us</strong>
                </h2>
                <p className="my-3">
                  If you have any questions relating to these Terms of Use, your
                  rights and obligations arising from these Terms and/or your
                  use of the Site and the Services, your Account or any other
                  matter, please contact us through{" "}
                  <a
                    className="font-medium text-gray-700 underline"
                    href="/contact"
                    target="_blank"
                  >
                    contact form
                  </a>
                  .
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
