import React from "react";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteHost, getWebsiteName } from "../../services/site-name";

export default function Privacy() {
  document.title = `${getWebsiteName()} - Politique de Cookies`;

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Politique de Cookies pour {getWebsiteName()}
                </span>
              </h1>
              <br />
              <br />
              <p>
                Ceci est la Politique de Cookies pour {getWebsiteName()},
                accessible depuis {getWebsiteHost()}
              </p>
              <br />
              <p>
                <strong>Que Sont les Cookies</strong>
              </p>
              <p>
                Comme le veut la pratique courante sur presque tous les sites
                web professionnels, ce site utilise des cookies, qui sont de
                petits fichiers téléchargés sur votre ordinateur pour améliorer
                votre expérience. Cette page décrit les informations qu'ils
                collectent, comment nous les utilisons et pourquoi nous devons
                parfois stocker ces cookies. Nous expliquerons également comment
                vous pouvez empêcher le stockage de ces cookies, bien que cela
                puisse affecter ou désactiver certaines fonctionnalités du site.
              </p>
              <br />{" "}
              <p>
                <strong>Comment Nous Utilisons les Cookies</strong>
              </p>
              <p>
                Nous utilisons des cookies pour diverses raisons, comme détaillé
                ci-dessous. Malheureusement, dans la plupart des cas, il n'y a
                pas d'options standard de l'industrie pour désactiver les
                cookies sans désactiver complètement les fonctionnalités et les
                caractéristiques qu'ils ajoutent à ce site. Il est recommandé de
                laisser tous les cookies activés si vous n'êtes pas sûr que vous
                en avez besoin ou non, au cas où ils seraient utilisés pour
                fournir un service que vous utilisez.
              </p>
              <br />
              <p>
                <strong>Désactivation des Cookies</strong>
              </p>
              <p>
                Vous pouvez empêcher la configuration des cookies en ajustant
                les paramètres de votre navigateur (consultez l'aide de votre
                navigateur pour savoir comment faire). Sachez que la
                désactivation des cookies affectera la fonctionnalité de ce site
                et de nombreux autres sites web que vous visitez. La
                désactivation des cookies entraînera généralement la
                désactivation de certaines fonctionnalités et caractéristiques
                de ce site. Il est donc recommandé de ne pas désactiver les
                cookies.
              </p>
              <br />
              <p>
                <strong>Les Cookies Que Nous Configurons</strong>
              </p>
              <ul>
                <li>
                  <p>• Cookies liés au compte</p>
                  <p>
                    Si vous créez un compte chez nous, nous utiliserons des
                    cookies pour la gestion du processus d'inscription et de
                    l'administration générale. Ces cookies seront généralement
                    supprimés lorsque vous vous déconnectez, mais dans certains
                    cas, ils peuvent rester pour se souvenir de vos préférences
                    de site lorsque vous êtes déconnecté.
                  </p>
                </li>

                <li>
                  <p>• Cookies liés à la connexion</p>
                  <p>
                    Nous utilisons des cookies lorsque vous êtes connecté pour
                    que nous puissions nous en souvenir. Cela vous évite de
                    devoir vous connecter à chaque fois que vous visitez une
                    nouvelle page. Ces cookies sont généralement supprimés ou
                    effacés lorsque vous vous déconnectez pour garantir que vous
                    ne pouvez accéder qu'aux fonctionnalités et aux zones
                    restreintes lorsque vous êtes connecté.
                  </p>
                </li>
              </ul>
              <br />
              <p>
                <strong>Cookies Tiers</strong>
              </p>
              <p>
                Dans certains cas particuliers, nous utilisons également des
                cookies fournis par des tiers de confiance. La section suivante
                détaille les cookies tiers que vous pourriez rencontrer sur ce
                site.
              </p>
              <ul>
                <li>
                  <p>
                    Ce site utilise Google Analytics, qui est l'une des
                    solutions d'analyse les plus répandues et les plus fiables
                    sur le web pour nous aider à comprendre comment vous
                    utilisez le site et les moyens d'améliorer votre expérience.
                    Ces cookies peuvent suivre des éléments tels que la durée de
                    votre visite sur le site et les pages que vous consultez
                    afin que nous puissions continuer à produire un contenu
                    attrayant.
                  </p>
                  <p>
                    Pour plus d'informations sur les cookies Google Analytics,
                    consultez la page officielle de Google Analytics.
                  </p>
                </li>
              </ul>
              <br />
              <p>
                <strong>Plus d'Informations</strong>
              </p>
              <p>
                Espérons que cela a clarifié les choses pour vous, comme cela a
                été mentionné précédemment, si vous n'êtes pas sûr que vous en
                avez besoin ou non, il est généralement plus sûr de laisser les
                cookies activés au cas où ils interagiraient avec l'une des
                fonctionnalités que vous utilisez sur notre site.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
