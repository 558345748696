import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "tailwindcss/tailwind.css";
import "./tailwind.css";
import Home from "./pages";
import Contact from "./pages/contact";
import AboutUs from "./pages/about_us";
import Merchant from "./pages/solutions/merchant";
import OTC from "./pages/solutions/otc";
import Wallet from "./pages/solutions/wallet";
import Cookies from "./pages/legal/cookies";
import Privacy from "./pages/legal/privacy";
import Terms from "./pages/legal/terms";
import FeeSchedule from "./pages/legal/fee-schedule";
import AmlPolicy from "./pages/legal/aml-policy";

export default function MyApp() {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : (
        <>
          <CookieConsent enableDeclineButton>
            🍪 &nbsp;&nbsp; This website uses cookies to enhance the user
            experience.
          </CookieConsent>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
        </>
      )}
    </div>
  );
}

const Routing = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/:locale/legal/cookies" element={<Cookies />} />
      <Route path="/:locale/legal/privacy" element={<Privacy />} />
      <Route path="/:locale/legal/fee-schedule" element={<FeeSchedule />} />
      <Route path="/:locale/legal/aml-policy" element={<AmlPolicy />} />
      <Route path="/:locale/legal/terms" element={<Terms />} />
      <Route path="/:locale/solutions/merchant" element={<Merchant />} />
      <Route path="/:locale/solutions/otc" element={<OTC />} />
      <Route path="/:locale/solutions/wallet" element={<Wallet />} />
      <Route path="/:locale/about_us" element={<AboutUs />} />
      <Route path="/:locale/contact" element={<Contact />} />
      <Route path="/:locale/home" element={<Home />} />
      <Route
        path="/*"
        element={
          ["en", "fr", "bg"].includes(location.pathname.substring(1, 3)) ? (
            <Navigate to={"/en/home"} />
          ) : (
            <Navigate
              to={
                (
                  `/${
                    navigator.language.substring(0, 2).toLocaleLowerCase() ||
                    "en"
                  }/` +
                  (location.pathname
                    .replace(/^\/.{2}\//, "")
                    .replace(/^\//, "") || "home")
                ).replace(/\/+/g, "/") + location.search
              }
            />
          )
        }
      />
    </Routes>
  );
};
