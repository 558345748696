import MainBigView from "../../blocks/big-buy-sell-crypto";
import EuropeLicence from "../../blocks/europe-licence";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function OTC() {
  document.title = "" + getWebsiteName() + " - Solutions - Wallet";

  return (
    <div className="bg-white">
      <Header />

      <MainBigView />

      <EuropeLicence />

      <Footer />
    </div>
  );
}
