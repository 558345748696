import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function FeeSchedule() {
  document.title = getWebsiteName() + " - Grille Tarifaire";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Grille Tarifaire
                </span>
              </h1>
              <br />
              <br />

              <p>
                Notez que les frais ci-dessous sont les frais par défaut. Ils
                peuvent être personnalisés pour chaque client sur demande.
              </p>
              <br />

              <p>
                <strong>Montants de commande minimum</strong>
              </p>

              <p>
                <strong>BTC</strong> - 0,0003 BTC
                <br />
                <strong>EUR</strong> - 15 EUR
                <br />
                <strong>USD</strong> - 15 USD
                <br />
                <strong>GBP</strong> - 15 GBP
              </p>

              <br />

              <p>
                <strong>Frais de commande</strong>
              </p>

              <p>
                Nous appliquons des frais fixes de 0,6 % sur toutes les
                commandes d'achat et de vente. Les frais sont toujours appliqués
                sur la devise de base.
              </p>

              <br />

              <p>
                Dans le cas de liens de paiement utilisant une devise
                intermédiaire, les éventuelles variations de prix du marché
                allant jusqu'à 1 % et les frais de commande présentés dans la
                section précédente seront à notre charge et remplacés par des
                frais uniques de 7 %. Une demande de paiement de 100 EUR en ETH
                se terminera toujours par des frais finaux de 7 EUR, et le
                commerçant recevra donc 93 EUR.
              </p>

              <br />

              <p>
                <strong>Autres frais</strong>
              </p>

              <p>
                Les frais de retrait sont de 3 EUR pour l'EUR, 3 USD pour l'USD,
                3 GBP pour la GBP et n'incluent pas les frais liés à la banque
                du bénéficiaire.
              </p>

              <br />

              <p>
                Les frais de dépôt par carte dépendent de notre processeur de
                paiement et sont sujets à modification. Les frais seront
                affichés lors du processus de paiement. Les frais seront ajoutés
                (et non déduits) de votre demande de dépôt initiale.
              </p>

              <br />

              <p>
                Nous n'appliquons pas de frais pour les transferts d'utilisateur
                à utilisateur utilisant la même devise.
              </p>

              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
