import {
  CurrencyEuroIcon,
  GlobeAltIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "../services/i18n";
import { getWebsiteLogoPrefix } from "../services/site-name";

export default function MainBigView() {
  const { t } = useTranslation({
    en: {
      title1: "Send and receive payments ",
      title2: "with ",
      title_enphasis: "crypto wallets",
      description:
        "Reduce regulatory overhead and fees with crypto wallets payments",
      "solutions.crypto_wallet": "Crypto Wallet",
      "solutions.crypto_wallet.description":
        "Your crypto wallet to ease your payment anywhere.",
      "solutions.worldwide_payments": "Worldwide payments",
      "solutions.worldwide_payments.description":
        "Use your wallet to pay anywhere in the world.",
      "solutions.instant_payments": "Instant payments",
      "solutions.instant_payments.description":
        "Send money directly from your bank to your wallet.",
    },
    fr: {
      title1: "Envoyez et recevez ",
      title2: "des paiements en ",
      title_enphasis: "crypto",
      description:
        "Réduisez les frais et les surcharges juridiques avec des paiements en cryptos",
      "solutions.crypto_wallet": "Portefeuille de cryptos",
      "solutions.crypto_wallet.description":
        "Votre portefeuille crypto pour faciliter vos paiements.",
      "solutions.worldwide_payments": "Paiements à l'étranger",
      "solutions.worldwide_payments.description":
        "Utilisez votre portefeuille pour payer à l'étranger.",
      "solutions.instant_payments": "Paiements immédiats",
      "solutions.instant_payments.description":
        "Envoyez de l'argent directement de votre banque vers votre portefeuille.",
    },
    bg: {
      title1: "Изпращайте и получавайте плащания ",
      title2: "с ",
      title_enphasis: "крипто портфейли",
      description:
        "Намалете регулаторните разходи и таксите с плащания чрез крипто портфейли",
      "solutions.crypto_wallet": "Крипто Портфейл",
      "solutions.crypto_wallet.description":
        "Вашият крипто портфейл за лесни плащания навсякъде.",
      "solutions.worldwide_payments": "Плащания в чужбина",
      "solutions.worldwide_payments.description":
        "Използвайте портфейла си, за да платите навсякъде по света.",
      "solutions.instant_payments": "Моментални плащания",
      "solutions.instant_payments.description":
        "Изпращайте пари директно от банката си към портфейла си.",
    },
  });

  return (
    <div className="relative overflow-hidden	">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <div className="lg:visible invisible bg-blue-gradient diagonal-background-left"></div>
      </div>

      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 left-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-12 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="#FFFFFF44" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>

      <div
        className="lg:visible invisible relative flex justify-between items-center max-w-7xl mx-auto md:justify-start md:space-x-10 lg:px-8"
        aria-hidden="true"
      >
        <img
          className="animate-float absolute top-0 left-6 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          src={"/assets/" + getWebsiteLogoPrefix() + "previews/pay-company.svg"}
          alt="Payment view"
          style={{ width: 400, top: 24 }}
        />
      </div>

      <div className="mb-40 relative mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-right">
          <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
            <span className="block xl:inline">
              {t("title1")} <br /> {t("title2")}
              <span className="text-indigo-600 xl:inline">
                {t("title_enphasis")}
              </span>
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mr-0">
            {t("description")}
          </p>
        </div>
        <br />
        <br />

        <div className="mt-10 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mr-0">
          <div className="relative mt-6">
            <dt>
              <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                <CurrencyEuroIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
                {t("solutions.crypto_wallet")}
              </p>
            </dt>
            <dd className="mr-16 text-base text-gray-400  text-right">
              {t("solutions.crypto_wallet.description")}
            </dd>
          </div>

          <div className="relative mt-6">
            <dt>
              <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
                {t("solutions.worldwide_payments")}
              </p>
            </dt>
            <dd className="mr-16 text-base text-gray-400  text-right">
              {t("solutions.worldwide_payments.description")}
            </dd>
          </div>

          <div className="relative mt-6">
            <dt>
              <div className="right-0 absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
                <SwitchVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="mr-16 text-lg leading-6 font-medium text-gray-900 text-right">
                {t("solutions.instant_payments")}
              </p>
            </dt>
            <dd className="mr-16 text-base text-gray-400  text-right">
              {t("solutions.instant_payments.description")}
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
}
