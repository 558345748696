import { useParams } from "react-router";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";
import PrivacyFr from "./privacy-fr";
import PrivacyBg from "./privacy-bg";

export default function Privacy() {
  let { locale } = useParams();

  /*
  if (locale === "fr") {
    return <PrivacyFr />;
  }*/

  if (locale === "bg") {
    return <PrivacyBg />;
  }

  document.title = getWebsiteName() + " - Privacy Policy";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Privacy Policy for {getWebsiteName()}
                </span>
              </h1>
              <br />
              <br />

              <section>
                <h2>Last Updated: January 1, 2024</h2>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>1. Introduction</strong>
                </h2>
                <p className="my-3">
                  This privacy notice applies to the processing activities
                  performed by {getWebsiteName()} to the personal data of its
                  clients, prospective clients, and website visitors. Your
                  privacy is of the utmost importance to us. It is our policy to
                  safeguard the confidentiality of information and respect the
                  privacy of individuals. Please see below for information about
                  how we manage personal data, and for information about your
                  rights with respect to the processing of your personal data.
                </p>
                <div className="divider divider--light"></div>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>2. Definitions</strong>
                </h2>
                <p className="my-3">
                  The following terms are defined as follows:
                </p>
                <ul className="list text--regular text--thin">
                  <li>“AML” means anti-money laundering.</li>
                  <li>
                    “Digital Asset” means any digital representation of value
                    that may be traded via {getWebsiteName()}’s services,
                    excluding non-fungible tokens.
                  </li>
                  <li>“We”, “Us”, refers to {getWebsiteName()}.</li>
                  <li>
                    “Personal data” refers to any information relating to an
                    identified or identifiable natural person, including names,
                    identification numbers, location data, an online identifier,
                    or to one or more factors specific to the physical,
                    economic, cultural or social identity of a natural person.
                  </li>
                  <li>
                    “VASP Services” means exchange between digital assets and
                    fiat currencies; exchange between one or more forms of
                    digital assets; transfer of digital assets, that is to say,
                    conduct a transaction on behalf of another person that moves
                    a digital asset from one digital asset address or account to
                    another; and act as a custodian wallet provider.
                  </li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>3. Your Data Controller</strong>
                </h2>
                <p className="my-3">
                  Our services are provided by {getWebsiteName()}. You are
                  contracting with {getWebsiteName()} Ltd., registered in the
                  Commercial register of Register Agency under Unified
                  Identification Code 207168127, having its seat and registered
                  office in Sofia 1124, 12 B Tsar Ivan Asen II str.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>4. How do we protect personal data?</strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} takes the security of personal data
                  incredibly seriously. Please see here for further detail about
                  our information security practices, and here for general
                  security information.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>5. Information we may collect about you</strong>
                </h2>
                <p className="my-3">
                  We obtain information about you in a number of ways through
                  your use services, including through the account opening
                  process, onboarding and verification, transaction management
                  and monitoring or from information provided in the course of
                  on-going support service communications.
                </p>
                <p className="my-3">
                  In order to open an account with us, you must first complete
                  and submit a “create account” form to us by completing the
                  required information. By completing this form, you are
                  requested to disclose personal data in order to enable
                  {getWebsiteName()} to assess your application and comply with
                  the relevant laws and regulations.
                </p>
                <p className="my-3">
                  The minimum information required for entering into a contract
                  governing your use of our services, and for enabling us to
                  comply with our statutory obligations in respect of anti-money
                  laundering and crime and fraud prevention, is biographical
                  information and contact information, verification information,
                  PEP information (defined below), and financial information.
                  Without this information, we cannot commence, or continue to
                  perform our services to you.
                </p>
                <p className="my-3">
                  The information that we collect from you is as follows:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Full name, residential address and contact details (e.g.
                    email address, telephone number etc.), date of birth, place
                    of birth, gender, citizenship (“Biographical information and
                    contact information”);
                  </li>
                  <li>
                    Bank account information, wallet addresses, credit card
                    details, details about your source of funds, assets and
                    liabilities, and information relating to economic and trade
                    sanctions lists (“Financial information”);
                  </li>
                  <li>
                    Trading account balances, trading activity (“Trading
                    information”);
                  </li>
                  <li>
                    Information on whether you (or someone close to you) holds a
                    prominent public function (“PEP information”);
                  </li>
                  <li>
                    Verification information, which includes information
                    necessary to verify your identity such as a passport, selfie
                    photos / videos, login credentials or Government-issued
                    identity card (“Verification information”);
                  </li>
                  <li>
                    Other personal data or commercial and/or identification
                    information – Information we, in our sole discretion, deem
                    necessary to comply with our legal obligations under various
                    AML obligations, such as under the European Union’s 5th AML
                    Directive (“Other information”).
                  </li>
                </ul>
                <p className="my-3">
                  Information we collect about you automatically:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Browser information – Information that is automatically
                    collected via analytics systems providers from your browser,
                    including your IP address, domain name, any external page
                    that referred you to us, your login information, browser
                    type and version, time zone setting, browser plug-in types
                    and versions, operating system, and platform (“Browser
                    information”);
                  </li>
                  <li>
                    Log information – Information that is generated by your use
                    of {getWebsiteName()} website, services, or tools operated
                    by
                    {getWebsiteName()} that is automatically collected and
                    stored in our log records. This may include device
                    information such as device identifier, device operating
                    system and model, device storage, Media Access Control (MAC)
                    address, signals relating to user behaviour and device
                    interaction, battery usage, location information, network
                    address, system activity and any internal and external
                    information related to pages that you visit, including the
                    full Uniform Resource Locators (URL) clickstream to, through
                    and from our Website or App (including date and time, page
                    response times, download errors, length of visits to certain
                    pages, page interaction information such as scrolling,
                    clicks, and mouse-overs, and methods used to browse away
                    from the page (“Log information”).
                  </li>
                </ul>
                <p className="my-3">
                  Information we receive about you from other sources.
                </p>
                <p className="my-3">
                  We also receive information about you from third parties such
                  as your payment providers, our service providers assisting
                  with AML, fraud, and security compliance, and through publicly
                  available sources. For example:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    The banks you use to transfer money to us will provide us
                    with your basic personal data, such as your name and
                    address, as well as your financial information such as your
                    bank account details;
                  </li>
                  <li>
                    Advertising networks, analytics providers and search
                    information providers may provide us with anonymized or
                    de-identified information about you, such as confirming how
                    you found our website.
                  </li>
                  <li>
                    Our service providers may provide us with information
                    relating to fraud, security, sanctions and AML and other
                    risks, for example, confirmation of identity attributes, and
                    information about any attributes linked to such matters.
                  </li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    6. Our legal justification for processing personal data
                  </strong>
                </h2>
                <p className="my-3">
                  Your personal data are collected and processed in order for
                  {getWebsiteName()} to perform the services requested by
                  clients and due to {getWebsiteName()}’s obligations to comply
                  with Bulgarian AML/CFT legislation transposed in accordance
                  with EU regulations.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>7. Disclosure of your personal data</strong>
                </h2>
                <p className="my-3">
                  If {getWebsiteName()} discloses your personal data to service
                  providers and business partners, in order to perform the
                  services requested by clients or to comply with our legal and
                  regulatory obligations, such providers and partners may store
                  your personal data within their own systems. We require them
                  to protect the confidentiality of this personal data, and
                  comply with all relevant privacy and data protection laws.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>8. Where we store your personal data</strong>
                </h2>
                <p className="my-3">
                  Our operations are supported by a network of computers,
                  servers, other infrastructure and information technology, and
                  third-party service providers. We and our third-party service
                  providers and business partners store and process your
                  personal data in the European Union.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    9. Transfers of personal data outside of the European
                    Economic Area (EEA)
                  </strong>
                </h2>
                <p className="my-3">
                  We may transfer your personal data outside to service
                  providers and business partners. Transfers outside of the EEA
                  shall be in accordance with the General Data Protection
                  Regulation (EU) 2016/679.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    10. Privacy when using digital assets and blockchains
                  </strong>
                </h2>
                <p className="my-3">
                  Your use of digital assets may be recorded on a public
                  blockchain. Public blockchains are distributed ledgers,
                  intended to immutably record transactions across wide networks
                  of computer systems. Many blockchains are open to forensic
                  analysis which can lead to re-identification of transacting
                  individuals and the revelation of personal data, especially
                  when blockchain data is combined with other data.
                </p>
                <p className="my-3">
                  As blockchains are decentralized or third-party networks which
                  are not controlled or operated by {getWebsiteName()}, we are
                  not able to erase, modify, or alter personal data on such
                  networks.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>11. Data retention</strong>
                </h2>
                <p className="my-3">
                  When personal data is no longer necessary for the purposes for
                  which it may lawfully be processed, we will remove any details
                  that will identify you, or we will securely destroy the
                  relevant records. We may need to maintain records for a
                  significant period of time after you cease being our client
                  for legal or regulatory reasons, for example when we need to
                  retain information to help manage a dispute or legal claim.
                  Additionally, we are subject to certain anti-money laundering
                  laws which may require us to retain the following for a period
                  (e.g., 5 years) after our business relationship with you has
                  ended:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    A copy of the records we used in order to comply with our
                    client due diligence obligations;
                  </li>
                  <li>
                    Supporting evidence and records of transactions with you,
                    and your relationship with us.
                  </li>
                </ul>
                <p className="my-3">
                  We may keep your personal data for longer than 5 years if we
                  cannot delete it for legal, regulatory, or technical reasons.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>12. Cookies</strong>
                </h2>
                <p className="my-3">
                  When you use our services or visit our website, we may place
                  tiny data files called cookies, flash cookies, pixel tags, or
                  other tracking tools (herein, “Cookies”) on your computer or
                  other devices used when engaging with us. We use cookies to
                  help us recognize you as a customer, collect information about
                  your use of our products and services, to better customize our
                  services and content for you, and to collect information about
                  your computer or other access devices to ensure our compliance
                  with fraud, security, sanctions and AML obligations.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>13. Your rights regarding your personal data</strong>
                </h2>
                <p className="my-3">
                  The rights that are available to you in relation to the
                  personal data we process are outlined below. You may request
                  to exercise these rights subject to any limitations provided
                  for under applicable data protection laws.
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    <strong>Access:</strong> You can ask us to confirm whether
                    we are processing your personal data and, if so, what
                    information we process and to provide you with a copy of
                    that information.
                  </li>
                  <li>
                    <strong>Rectification:</strong> It is important to us that
                    your personal data is up to date. We will take all
                    reasonable steps to make sure that your personal data
                    remains accurate, complete and up-to-date. Please inform us
                    if your personal data changes. If the personal data we hold
                    about you is inaccurate or incomplete, you are entitled to
                    have it rectified. If we have disclosed your personal data
                    to others, we will let them know about the rectification
                    where possible. If you ask us, and if possible and lawful to
                    do so, we will also inform you with whom we have shared your
                    personal data. You may inform us at any time that your
                    personal details have changed by emailing us at support@
                    {getWebsiteName()}.plus. Subject to applicable law,
                    {getWebsiteName()} will change your personal data in
                    accordance with your instructions. To proceed with such
                    requests, in some cases we may need supporting documents
                    from you as proof i.e. personal data that we are required to
                    keep for regulatory or other legal purposes.
                  </li>
                  <li>
                    <strong>Erasure:</strong> You can ask us to delete or remove
                    your personal data in certain circumstances. Such requests
                    may be subject to any retention limits we are required to
                    comply with in accordance with applicable laws and
                    regulations. If we have disclosed your personal data to
                    others, we will let them know about the erasure request
                    where possible. If you ask us, and if possible and lawful to
                    do so, we will also inform you with whom we have shared your
                    personal data.
                  </li>
                  <li>
                    <strong>Processing restrictions:</strong> You can ask us to
                    block or suppress the processing of your personal data in
                    certain circumstances such as if you contest the accuracy of
                    that personal data or object to us processing it. It will
                    not stop us from storing your personal data. If we have
                    disclosed your personal data to others, we will let them
                    know about the restriction of processing if possible. If you
                    ask us, and if possible and lawful to do so, we will also
                    inform you with whom we have shared your personal data.
                  </li>
                  <li>
                    <strong>Data portability:</strong> In certain circumstances
                    you may have the right to obtain personal data you have
                    provided to us, in a structured, commonly used and
                    machine-readable format, and to re-use it elsewhere or ask
                    us to transfer this to a third party of your choice, where
                    technically feasible.
                  </li>
                  <li>
                    <strong>Objection:</strong> You can ask us to stop
                    processing your personal data, and we will do so, if we are:
                    <ul>
                      <li>
                        Relying on our own or someone else’s legitimate
                        interests to process your personal data except if we can
                        demonstrate compelling legal grounds for the processing
                        or for the establishment, exercise or defence of legal
                        claims;
                      </li>
                      <li>
                        Processing your personal data for direct marketing; or
                      </li>
                      <li>
                        Processing your personal data for research unless we
                        reasonably believe such processing is necessary for the
                        performance of a task carried out for reasons of public
                        interest (such as by a regulatory or enforcement
                        agency).
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Automated decision-making and profiling:</strong> If
                    we have made a decision about you based solely on an
                    automated process (e.g. through automatic profiling) that
                    affects your ability to access our services or has another
                    significant effect on you, you can request not to be subject
                    to such a decision unless we can demonstrate to you that
                    such decision is necessary for entering into, or the
                    performance of, a contract between you and us. Even if a
                    decision is necessary for entering into or performing a
                    contract, you may contest the decision and require human
                    intervention. We may not be able to offer our services to
                    you, if we agree to such a request (i.e. end our
                    relationship with you).
                  </li>
                </ul>
                <p className="my-3">
                  Complaints: You have the right to complain to a competent data
                  protection authority. We ask that you first contact support@
                  {getWebsiteName()}.plus to give us an opportunity to address
                  any concerns.
                </p>
                <p className="my-3">
                  Withdraw consent: You have the right to withdraw consent to
                  processing based on consent at any time. Note this will not
                  affect the lawfulness of processing based on consent prior to
                  the withdrawal of consent or on grounds where consent is not
                  required.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>14. Changes to this privacy notice</strong>
                </h2>
                <p className="my-3">
                  Our privacy notice is reviewed regularly in light of new
                  regulations, technologies, and any changes to our business
                  operations. Any personal data we process will be governed by
                  our most recent privacy notice. We will update the “Last
                  updated” date accordingly at the beginning of this privacy
                  notice. Please review this privacy notice from time to time.
                  We will announce any material changes to this privacy notice
                  on our website.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
