import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function AmlPolicy() {
  document.title = getWebsiteName() + " - Политика срещу Пране на Пари";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Политика срещу Пране на Пари
                </span>
              </h1>

              <br />
              <br />

              <section>
                <h2>
                  <strong>
                    Политика срещу Пране на Пари (ППП) и Борба с Финансиране на
                    Тероризъм (ФТ) на {getWebsiteName()} ООД
                  </strong>
                </h2>
                <p className="my-3">
                  Прането на пари се дефинира като процесът, чрез който
                  идентичността на приходите от престъпление се замаскира така,
                  че да се създаде впечатление за легитимен доход. Престъпници
                  специално целят финансовите услуги, чрез които се опитват да
                  перат криминални приходи без да бъдат забелязани или под
                  съмнение от фирмите.
                </p>
                <p className="my-3">
                  В отговор на мащаба и въздействието на парите, пране на пари,
                  Европейският съюз е издал Директиви, предназначени за борба с
                  парите, пране на пари и тероризма. Тези Директиви, заедно с
                  регулации, правила и насоки от индустрията, образуват основата
                  на нашите задължения по отношение на ППП/ФТ и определят
                  нарушенията и наказанията за непослушание.
                </p>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>Политика срещу Пране на Пари (ППП):</strong>
                </h2>
                <p className="my-3">
                  Политиката за борба срещу парите на {getWebsiteName()} е
                  проектирана да предотвратява парите, пране на пари, като
                  изпълнява европейските стандарти за борба срещу парите и
                  финансирането на тероризма, включително необходимостта от
                  наличие на подходящи системи и контроли за намаляване на риска
                  фирмата да бъде използвана за осъществяване на финансови
                  престъпления. Тази ППП определя минималните стандарти, които
                  трябва да бъдат спазвани, и включва:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Създаване и поддържане на Рисков Подход (РП) към оценката и
                    управлението на рисковете от парите, пране на пари и
                    финансиране на тероризма, предизвикани от фирмата;
                  </li>
                  <li>
                    Създаване и поддържане на процедури за Достоверност на
                    Клиента (ДКК) въз основа на риска, идентификация, проверка и
                    Познай Своя Клиент (ПСК) процедури, включително повишена
                    дилъджънс за клиенти, представящи по-висок риск, като
                    Политически Изложени Лица (ПИЛ);
                  </li>
                  <li>
                    Създаване и поддържане на системи и процедури, базирани на
                    риска, за наблюдение на активността на клиентите;
                  </li>
                  <li>
                    Създаване на процедури за докладване на подозрителна дейност
                    вътрешно и пред съответните правоприлагащи органи, когато е
                    подходящо;
                  </li>
                  <li>
                    Поддържане на подходящи записи за минималните предписани
                    периоди;
                  </li>
                  <li>
                    Предоставяне на обучение и повишаване на осведомеността сред
                    всички съответни служители.
                  </li>
                </ul>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>Политика за Санкции:</strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} е забранено да сключва сделки с лица,
                  компании и държави, които се намират в предписани списъци с
                  санкции. Следователно {getWebsiteName()} ще извършва проверки
                  срещу списъците със санкции на Организацията на обединените
                  нации, Европейския съюз, Британския съвет и Управлението за
                  контрол на активите в чужбина (OFAC) на САЩ във всички
                  юрисдикции, в които ние действаме.
                </p>
              </section>
              <br />
              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
