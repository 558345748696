import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";

export default function PrivacyBg() {
  document.title = getWebsiteName() + " - Политика за поверителност";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Политика за поверителност за {getWebsiteName()}
                </span>
              </h1>
              <br />
              <br />

              <section>
                <h2>Последна актуализация: 1 януари 2024</h2>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>1. Въведение</strong>
                </h2>
                <p className="my-3">
                  Това известие за поверителност се отнася за дейностите по
                  обработка, извършвани от {getWebsiteName()} по отношение на
                  личните данни на своите клиенти, потенциални клиенти и
                  посетители на уебсайта. Вашата поверителност е от изключителна
                  важност за нас. Нашата политика е да защитаваме
                  конфиденциалността на информацията и да уважаваме личната
                  неприкосновеност на лицата. Моля, вижте по-долу за информация
                  относно начина, по който управляваме личните данни, и за
                  информация относно вашите права по отношение на обработката на
                  вашите лични данни.
                </p>
                <div className="divider divider--light"></div>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>2. Определения</strong>
                </h2>
                <p className="my-3">
                  Следните термини се определят по следния начин:
                </p>
                <ul className="list text--regular text--thin">
                  <li>„AML“ означава противодействие на паричното пране.</li>
                  <li>
                    „Цифров актив“ означава всяко цифрово представяне на
                    стойност, което може да бъде търгувано чрез услугите на
                    {getWebsiteName()}, с изключение на невзаимозаменяеми
                    токени.
                  </li>
                  <li>„Ние“, „Нас“ се отнася до {getWebsiteName()}.</li>
                  <li>
                    „Лични данни“ се отнася до всяка информация, свързана с
                    идентифицирано или идентифицируемо физическо лице,
                    включително имена, идентификационни номера, местоположение,
                    онлайн идентификатор, или до един или повече фактори,
                    специфични за физическата, икономическата, културната или
                    социалната идентичност на физическо лице.
                  </li>
                  <li>
                    „Услуги VASP“ означават обмяна между цифрови активи и фиатни
                    валути; обмяна между една или повече форми на цифрови
                    активи; прехвърляне на цифрови активи, тоест, провеждане на
                    транзакция от името на друго лице, която премества цифров
                    актив от един цифров адрес или акаунт на друг; и действие
                    като доставчик на портфейл-хранител.
                  </li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>3. Вашият контролер на данни</strong>
                </h2>
                <p className="my-3">
                  Нашите услуги се предоставят от {getWebsiteName()}. Вие
                  сключвате договор с {getWebsiteName()} Ltd., регистриран в
                  Търговския регистър на Агенцията по вписванията под Единен
                  идентификационен код 207168127, със седалище и адрес на
                  управление в София 1124, ул. Цар Иван Асен II № 12.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>4. Как защитаваме личните данни?</strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} приема сигурността на личните данни
                  изключително сериозно. Моля, вижте тук за повече подробности
                  относно нашите практики по информационна сигурност, и тук за
                  обща информация за сигурността.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>5. Информация, която може да събираме за вас</strong>
                </h2>
                <p className="my-3">
                  Ние получаваме информация за вас по няколко начина чрез вашето
                  използване на услугите, включително по време на процеса на
                  откриване на акаунт, процеса на включване и проверка,
                  управление на транзакции и мониторинг или от информация,
                  предоставена по време на комуникация за поддържаща услуга.
                </p>
                <p className="my-3">
                  За да откриете акаунт при нас, първо трябва да попълните и
                  изпратите към нас формуляр „създаване на акаунт“, като
                  попълните изискваната информация. Като попълните този
                  формуляр, вие разкривате лични данни, за да може{" "}
                  {getWebsiteName()} да оцени вашата заявка и да се съобрази с
                  съответните закони и регулации.
                </p>
                <p className="my-3">
                  Минималната информация, изисквана за сключване на договор за
                  използване на нашите услуги, и за да можем да се съобразим с
                  нашите законови задължения по отношение на противодействие на
                  паричното пране и предотвратяване на престъпления и измами,
                  включва биографична информация и контактна информация,
                  информация за проверка, информация за PEP (определена по-долу)
                  и финансова информация. Без тази информация не можем да
                  започнем или да продължим да предоставяме нашите услуги на
                  вас.
                </p>
                <p className="my-3">
                  Информацията, която събираме от вас, е както следва:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Пълно име, адрес за пребиваване и контактни данни (напр.
                    имейл адрес, телефонен номер и др.), дата на раждане, място
                    на раждане, пол, гражданство („Биографична информация и
                    контактна информация“);
                  </li>
                  <li>
                    Информация за банкова сметка, адреси на портфейли,
                    информация за кредитни карти, подробности относно източника
                    на средства, активи и пасиви, информация, свързана със
                    списъци за икономически и търговски санкции („Финансова
                    информация“);
                  </li>
                  <li>
                    Баланси на търговските сметки, търговска активност
                    („Търговска информация“);
                  </li>
                  <li>
                    Информация дали вие (или някой близък до вас) заема видно
                    обществено положение („Информация за ПЕП“);
                  </li>
                  <li>
                    Информация за проверка, включваща информация, необходима за
                    потвърждаване на вашата самоличност, като паспорт, селфи
                    снимки / видео, данни за вход или лична карта, издадена от
                    правителството („Информация за проверка“);
                  </li>
                  <li>
                    Други лични данни или търговска и/или идентификационна
                    информация - Информация, която ние, по наше усмотрение,
                    смятаме за необходима за спазване на нашите правни
                    задължения по различни задължения за противодействие на
                    изпирането на пари, като съгласно Петата директива за АМЛ на
                    Европейския съюз („Друга информация“).
                  </li>
                </ul>
                <p className="my-3">
                  Информация, която събираме автоматично за вас:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Информация за браузъра - Информация, която автоматично се
                    събира от системи за анализ на вашите данни от вашия
                    браузър, включително вашият IP адрес, домейн име, всяка
                    външна страница, която ви е препратила към нас, вашите данни
                    за вход, тип и версия на браузъра, настройки на часовата
                    зона, типове и версии на браузърни добавки, операционна
                    система и платформа („Информация за браузъра“);
                  </li>
                  <li>
                    Информация за регистрация – Информация, която се генерира от
                    вашето използване на уебсайта {getWebsiteName()}, услугите
                    или инструментите, оперирани от {getWebsiteName()}, която
                    автоматично се събира и съхранява в нашите регистри. Това
                    може да включва информация за устройството, като
                    идентификатор на устройството, операционна система и модел
                    на устройството, памет на устройството, MAC адрес, сигнали,
                    свързани с поведението на потребителя и взаимодействието с
                    устройството, използване на батерията, информация за
                    местоположението, мрежов адрес, системна активност и всяка
                    вътрешна и външна информация, свързана с посетените от вас
                    страници, включително пълните Униформирани Ресурс Локатори
                    (URL) поток към, през и от нашия Уебсайт или Приложение
                    (включително дата и час, време на отговор на страницата,
                    грешки при изтегляне, продължителност на посещенията на
                    определени страници, информация за взаимодействие със
                    страницата, като превъртане, кликвания и мишка над, и
                    методи, използвани за напускане на страницата („Информация
                    за регистрация“).
                  </li>
                </ul>
                <p className="my-3">
                  Информация, която получаваме за вас от други източници.
                </p>
                <p className="my-3">
                  Също така получаваме информация за вас от трети страни, като
                  вашите доставчици на плащания, нашите доставчици на услуги,
                  които помагат за съответствие с АМЛ, измами и сигурност, и
                  чрез публично достъпни източници. Например:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Банките, които използвате за превод на пари към нас, ще ни
                    предоставят вашите основни лични данни, като вашето име и
                    адрес, както и вашата финансова информация, като данни за
                    вашата банкова сметка;
                  </li>
                  <li>
                    Рекламни мрежи, доставчици на анализ и доставчици на
                    информация за търсене могат да ни предоставят анонимизирана
                    или деидентифицирана информация за вас, като например
                    потвърждение как сте открили нашия уебсайт.
                  </li>
                  <li>
                    Нашите доставчици на услуги могат да ни предоставят
                    информация, свързана с измами, сигурност, санкции и АМЛ и
                    други рискове, например потвърждение на атрибути на
                    самоличността и информация за всякакви атрибути, свързани с
                    такива въпроси.
                  </li>
                </ul>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    6. Нашето правно оправдание за обработка на лични данни
                  </strong>
                </h2>
                <p className="my-3">
                  Вашите лични данни се събират и обработват, за да може
                  {getWebsiteName()} да изпълнява услугите, поискани от
                  клиентите, и поради задълженията на {getWebsiteName()} да
                  спазва българското законодателство за АМЛ/ФТ, транспонирано в
                  съответствие с регламентите на ЕС.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>7. Разкриване на вашите лични данни</strong>
                </h2>
                <p className="my-3">
                  Ако {getWebsiteName()} разкрива вашите лични данни на
                  доставчици на услуги и бизнес партньори, с цел изпълнение на
                  заявени от клиентите услуги или за спазване на нашите законови
                  и регулаторни задължения, такива доставчици и партньори могат
                  да съхраняват вашите лични данни в техните собствени системи.
                  Ние изискваме от тях да защитават конфиденциалността на тези
                  лични данни и да спазват всички съответни закони за защита на
                  личните данни и данните.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>8. Къде съхраняваме вашите лични данни</strong>
                </h2>
                <p className="my-3">
                  Нашата дейност се поддържа от мрежа от компютри, сървъри,
                  друга инфраструктура и информационни технологии, и трети
                  страни доставчици на услуги. Ние и нашите трети страни
                  доставчици на услуги и бизнес партньори съхраняваме и
                  обработваме вашите лични данни в Европейския съюз.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    9. Трансфери на лични данни извън Европейската икономическа
                    зона (ЕИЗ)
                  </strong>
                </h2>
                <p className="my-3">
                  Ние можем да прехвърляме вашите лични данни извън ЕИЗ на
                  доставчици на услуги и бизнес партньори. Трансферите извън ЕИЗ
                  ще се извършват в съответствие с Общия регламент за защита на
                  данните (ЕС) 2016/679.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    10. Поверителност при използване на цифрови активи и
                    блокчейни
                  </strong>
                </h2>
                <p className="my-3">
                  Вашето използване на цифрови активи може да бъде записано на
                  публичен блокчейн. Публичните блокчейни са разпределени
                  регистри, предназначени да записват неизменно транзакции в
                  широки мрежи от компютърни системи. Много блокчейни са
                  отворени за форензичен анализ, което може да доведе до
                  повторно идентифициране на транзакционни индивиди и разкриване
                  на лични данни, особено когато данните от блокчейна се
                  комбинират с други данни.
                </p>
                <p className="my-3">
                  Тъй като блокчейните са децентрализирани или мрежи на трети
                  страни, които не се контролират или управляват от{" "}
                  {getWebsiteName()}, ние не можем да изтрием, модифицираме или
                  променяме лични данни в такива мрежи.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>11. Запазване на данни</strong>
                </h2>
                <p className="my-3">
                  Когато личните данни вече не са необходими за целите, за които
                  могат да бъдат законосъобразно обработвани, ние ще премахнем
                  всички подробности, които ви идентифицират, или ще унищожим
                  безопасно съответните записи. Може да се наложи да поддържаме
                  записи за значителен период от време, след като престанете да
                  бъдете наш клиент, поради правни или регулаторни причини,
                  например когато се налага да запазим информация за управление
                  на спор или правен иск. Освен това, ние подлежим на определени
                  закони за противодействие на прането на пари, които могат да
                  изискват от нас да запазим следното за период (например, 5
                  години) след като нашите делови отношения с вас са приключили:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Копие на записите, които сме използвали, за да спазим нашите
                    задължения за проверка на клиентите;
                  </li>
                  <li>
                    Подкрепящи доказателства и записи на транзакции с вас и
                    вашите отношения с нас.
                  </li>
                </ul>
                <p className="my-3">
                  Ние можем да запазим вашите лични данни за по-дълъг период от
                  5 години, ако по правни, регулаторни или технически причини не
                  можем да ги изтрием.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>12. Бисквитки</strong>
                </h2>
                <p className="my-3">
                  Когато използвате нашите услуги или посещавате нашия уебсайт,
                  ние можем да поставим малки файлове с данни, наречени
                  бисквитки, флаш бисквитки, пикселни тагове или други
                  инструменти за проследяване (наричани за кратко „Бисквитки“)
                  на вашия компютър или други устройства, използвани при
                  ангажиране с нас. Ние използваме бисквитки, за да ни помогнат
                  да ви разпознаем като клиент, да събираме информация за вашето
                  използване на нашите продукти и услуги, да персонализираме
                  нашите услуги и съдържание за вас и да събираме информация за
                  вашия компютър или други устройства за достъп, за да
                  гарантираме нашето спазване на задълженията в областта на
                  измамите, сигурността, санкциите и противодействието на
                  прането на пари.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    13. Вашите права по отношение на вашите лични данни
                  </strong>
                </h2>
                <p className="my-3">
                  По-долу са изброени правата, които са на ваше разположение във
                  връзка с личните данни, които обработваме. Можете да поискате
                  да упражните тези права, подлежащи на всички ограничения,
                  предвидени в приложимите закони за защита на данните.
                </p>

                <ul className="list text--regular text--thin">
                  <li>
                    <strong>Достъп:</strong> Можете да ни поискате да потвърдим
                    дали обработваме вашите лични данни и, ако да, каква
                    информация обработваме и да ви предоставим копие от тази
                    информация.
                  </li>
                  <li>
                    <strong>Коригиране:</strong> Важно е за нас вашите лични
                    данни да са актуални. Ще предприемем всички разумни стъпки,
                    за да гарантираме, че вашите лични данни остават точни,
                    пълни и актуални. Моля, информирайте ни, ако вашите лични
                    данни се променят. Ако личните данни, които имаме за вас, са
                    неточни или непълни, имате право да поискате те да бъдат
                    коригирани. Ако сме разкрили вашите лични данни на други
                    лица, ще ги уведомим за коригирането, където е възможно. Ако
                    ни поискате и ако е възможно и законно, също така ще ви
                    информираме с кого сме споделили вашите лични данни. Можете
                    по всяко време да ни уведомите, че вашите лични данни са се
                    променили, като ни изпратите имейл на support@
                    {getWebsiteName()}.plus. Съобразно приложимото
                    законодателство, {getWebsiteName()} ще промени вашите лични
                    данни в съответствие с вашите инструкции. За да продължим с
                    такива заявки, в някои случаи може да се наложи да ви
                    поискаме подкрепящи документи като доказателство т.е. лични
                    данни, които сме задължени да запазим за регулаторни или
                    други правни цели.
                  </li>
                  <li>
                    <strong>Изтриване:</strong> Можете да ни поискате да изтрием
                    или премахнем вашите лични данни в определени обстоятелства.
                    Такива заявки могат да бъдат подчинени на всички ограничения
                    за съхранение, на които сме задължени да се съобразяваме
                    според приложимите закони и разпоредби. Ако сме разкрили
                    вашите лични данни на други лица, ще ги уведомим за заявката
                    за изтриване, където е възможно. Ако ни поискате и ако е
                    възможно и законно, също така ще ви информираме с кого сме
                    споделили вашите лични данни.
                  </li>
                  <li>
                    <strong>Ограничения на обработката:</strong> Можете да ни
                    поискате да блокираме или потискаме обработката на вашите
                    лични данни в определени обстоятелства, като например ако
                    оспорвате точността на тези лични данни или възразявате
                    срещу тяхната обработка. Това няма да ни попречи да
                    съхраняваме вашите лични данни. Ако сме разкрили вашите
                    лични данни на други лица, ще ги уведомим за ограничението
                    на обработката, ако е възможно. Ако ни поискате и ако е
                    възможно и законно, също така ще ви информираме с кого сме
                    споделили вашите лични данни.
                  </li>
                  <li>
                    <strong>Преносимост на данните:</strong> При определени
                    обстоятелства може да имате право да получите лични данни,
                    които сте ни предоставили, в структуриран, общоприет и
                    машиночитаем формат и да ги използвате другаде или да ни
                    поискате да ги прехвърлим на трета страна по ваш избор,
                    където е технически осъществимо.
                  </li>
                  <li>
                    <strong>Възражение:</strong> Можете да ни поискате да спрем
                    обработката на вашите лични данни и ние ще го направим, ако
                    сме:
                    <ul>
                      <li>
                        Разчитащи на наши или чужди законни интереси за
                        обработка на вашите лични данни, освен ако не можем да
                        докажем належащи правни основания за обработката или за
                        установяването, упражняването или защитата на правни
                        претенции;
                      </li>
                      <li>
                        Обработващи вашите лични данни за директен маркетинг;
                        или
                      </li>
                      <li>
                        Обработващи вашите лични данни за изследвания, освен ако
                        не сме на мнение, че такава обработка е необходима за
                        изпълнението на задача, извършвана по причини на
                        обществен интерес (например от регулаторен или
                        контролиращ орган).
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>
                      Автоматизирано вземане на решения и профилиране:
                    </strong>{" "}
                    Ако сме взели решение за вас, базирано единствено на
                    автоматизиран процес (напр. чрез автоматично профилиране),
                    което засяга вашата способност да използвате нашите услуги
                    или има друг значителен ефект върху вас, можете да поискате
                    да не бъдете подлагани на такова решение, освен ако не можем
                    да ви убедим, че такова решение е необходимо за сключване на
                    договор между вас и нас или за изпълнението на такъв
                    договор. Дори ако решението е необходимо за сключване или
                    изпълнение на договор, можете да оспорите решението и да
                    изискате човешка намеса. Не можем да предложим нашите услуги
                    на вас, ако се съгласим с такова искане (т.е. да прекратим
                    отношенията си с вами).
                  </li>
                </ul>
                <p className="my-3">
                  Оплаквания: Имате право да подадете оплакване до компетентен
                  орган за защита на данните. Молим ви да се свържете първо с
                  support@{getWebsiteName()}.plus, за да ни дадете възможност да
                  разгледаме всякакви притеснения.
                </p>
                <p className="my-3">
                  Оттегляне на съгласие: Имате право да оттеглите съгласието си
                  за обработка, основаваща се на съгласие, по всяко време.
                  Обърнете внимание, че това няма да повлияе на
                  законосъобразността на обработката, основаваща се на съгласие
                  преди оттеглянето на съгласието или на основания, при които
                  съгласието не е необходимо.
                </p>
              </section>
              <br />
              <br />
              <section>
                <h2>
                  <strong>
                    14. Промени в това уведомление за поверителност
                  </strong>
                </h2>
                <p className="my-3">
                  Нашият документ за поверителност се преглежда редовно във
                  връзка с нови регулации, технологии и всякакви промени в
                  нашата бизнес дейност. Всякакви лични данни, които
                  обработваме, ще бъдат регулирани от нашето най-ново
                  уведомление за поверителност. Ще актуализираме датата на
                  "Последно обновено" съответно в началото на това уведомление
                  за поверителност. Моля, преглеждайте редовно това уведомление
                  за поверителност. Ще обявим всякакви съществени промени в това
                  уведомление за поверителност на нашия уебсайт.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
