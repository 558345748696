import { useTranslation } from "../services/i18n";
import { getWebsiteName } from "../services/site-name";

export default function EuropeLicence() {
  const { t } = useTranslation({
    en: {
      title: "Exchange of crypto-assets in Europe",
      description:
        "" +
        getWebsiteName() +
        " is one of the first platforms for exchange of crypto-assets operating at the European level.",
    },
    fr: {
      title: "Échange de crypto-actifs en europe",
      description:
        "" +
        getWebsiteName() +
        " est l'une des première plate-forme d'échange de crypto-actifs opérant au niveau européen.",
    },
    bg: {
      title: "Обмяна на крипто активи в Европа",
      description:
        "" +
        getWebsiteName() +
        " е една от първите платформи за обмяна на крипто активи, работеща на европейско ниво.",
    },
  });

  return (
    <div className="bg-indigo-800 py-12">
      <div className="mx-auto max-w-7xl">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
            <div>
              <h4 className="mt-4 text-4xl tracking-tight font-bold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-2xl xl:text-4xl">
                {t("title")}
              </h4>
              <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                {t("description")}
              </p>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
            <img
              src="/assets/europa.svg"
              className="mx-auto"
              style={{ height: "500px" }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
