import { useParams } from "react-router";
import Footer from "../../components/_footer";
import Header from "../../components/_header";
import { getWebsiteName } from "../../services/site-name";
import AmlPolicyFr from "./aml-policy-fr";
import AmlPolicyBg from "./aml-policy-bg";

export default function AmlPolicy() {
  let { locale } = useParams();

  if (locale === "fr") {
    return <AmlPolicyFr />;
  }

  if (locale === "bg") {
    return <AmlPolicyBg />;
  }

  document.title = getWebsiteName() + " - AML Policy";

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  AML Policy
                </span>
              </h1>

              <br />
              <br />

              <section>
                <h2>
                  <strong>
                    {getWebsiteName()} Limited Anti Money Laundering ("AML") and
                    Counter Terrorist Financing ("CTF") Policy
                  </strong>
                </h2>
                <p className="my-3">
                  Money laundering is defined as the process where the identity
                  of the proceeds of crime is so disguised that it gives an
                  impression of legitimate income. Criminals specifically target
                  financial services firms through which they attempt to launder
                  criminal proceeds without the firms’ knowledge or suspicion.
                </p>
                <p className="my-3">
                  In response to the scale and effect of money laundering, the
                  European Union has passed Directives designed to combat money
                  laundering and terrorism. These Directives, together with
                  regulations, rules and industry guidance, form the cornerstone
                  of our AML/CTF obligations and outline the offenses and
                  penalties for failing to comply.
                </p>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>Anti-Money Laundering (AML) Policy:</strong>
                </h2>
                <p className="my-3">
                  The {getWebsiteName()} AML Policy is designed to prevent money
                  laundering by meeting the European standards on combating
                  money laundering and terrorism financing, including the need
                  to have adequate systems and controls in place to mitigate the
                  risk of the firm being used to facilitate financial crime.
                  This AML Policy sets out the minimum standards which must be
                  complied with and includes:
                </p>
                <ul className="list text--regular text--thin">
                  <li>
                    Establishing and maintaining a Risk-Based Approach (RBA) to
                    the assessment and management of money laundering and
                    terrorist financing risks faced by the firm;
                  </li>
                  <li>
                    Establishing and maintaining risk-based Customer Due
                    Diligence (CDD), identification, verification and Know Your
                    Customer (KYC) procedures, including enhanced due diligence
                    for customers presenting a higher risk, such as Politically
                    Exposed Persons (PEPs);
                  </li>
                  <li>
                    Establishing and maintaining risk-based systems and
                    procedures for the monitoring of on-going customer activity;
                  </li>
                  <li>
                    Establishing procedures for reporting suspicious activity
                    internally and to the relevant law enforcement authorities
                    as appropriate;
                  </li>
                  <li>
                    Maintaining appropriate records for the minimum prescribed
                    periods;
                  </li>
                  <li>
                    Providing training for and raising awareness among all
                    relevant employees.
                  </li>
                </ul>
                <div className="divider divider--light"></div>
                <h2>
                  <strong>Sanctions Policy:</strong>
                </h2>
                <p className="my-3">
                  {getWebsiteName()} is prohibited from transacting with
                  individuals, companies and countries that are on prescribed
                  sanctions lists. {getWebsiteName()} will therefore screen
                  against United Nations, European Union, UK Treasury and US
                  Office of Foreign Assets Control (OFAC) sanctions lists in all
                  jurisdictions in which we operate.
                </p>
              </section>
              <br />
              <br />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
