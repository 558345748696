import { Switch } from "@headlessui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/_footer";
import Header from "../components/_header";
import { useTranslation } from "../services/i18n";
import { getWebsiteName } from "../services/site-name";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Contact() {
  const router = useParams();
  const navigate = useNavigate();

  const [agreed, setAgreed] = useState(false);
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");

  const { t } = useTranslation({
    en: {
      title: "Contact us",
      description:
        "Contact us for more information about " + getWebsiteName() + "",
      "success.thanks": "Thanks!",
      "success.title": "Your message has been sent.",
      "success.description": "We will get back to you as soon as possible.",
      "success.back": "Back to home",
      "contact.send": "Let's talk",
      "contact.first_name": "First name",
      "contact.last_name": "Last name",
      "contact.company": "Company",
      "contact.email": "Email",
      "contact.phone": "Phone",
      "contact.phone_country": "Country",
      "contact.message": "Message",
      "contact.agreed": "By selecting this, you agree to ",
    },
    fr: {
      title: "Contactez-nous",
      description:
        "Contactez-nous pour plus d'informations sur " + getWebsiteName() + "",
      "success.thanks": "Merci!",
      "success.title": "Votre message a été envoyé.",
      "success.description": "Nous vous répondrons dès que possible.",
      "success.back": "Retour à l'accueil",
      "contact.send": "Envoyer",
      "contact.first_name": "Prénom",
      "contact.last_name": "Nom",
      "contact.company": "Société",
      "contact.email": "Email",
      "contact.phone": "Téléphone",
      "contact.phone_country": "Pays",
      "contact.message": "Message",
      "contact.agreed": "En sélectionnant ceci, vous acceptez ",
    },
    bg: {
      title: "Свържете се с нас",
      description:
        "Свържете се с нас за повече информация за " + getWebsiteName() + "",
      "success.thanks": "Благодаря!",
      "success.title": "Вашето съобщение е изпратено.",
      "success.description": "Ще се свържем с вас възможно най-скоро.",
      "success.back": "Обратно към началната страница",
      "contact.send": "Изпрати",
      "contact.first_name": "Име",
      "contact.last_name": "Фамилия",
      "contact.company": "Компания",
      "contact.email": "Имейл",
      "contact.phone": "Телефон",
      "contact.phone_country": "Държава",
      "contact.message": "Съобщение",
      "contact.agreed": "Като изберете това, вие се съгласявате с ",
    },
  });

  const valid = agreed && message && email && firstName && lastName && company;

  if (
    new URLSearchParams((document as any).location.search).get("success") ===
    "1"
  ) {
    document.title = `${getWebsiteName()} - ${t("title")}`;

    return (
      <div className="container">
        <Header />

        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex" style={{ minHeight: "50vh" }}>
              <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
                {t("success.thanks")}
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    {t("success.title")}
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    {t("success.description")}
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <a
                    href={"/" + (router.locale || "en") + "/"}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("success.back")}
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>

        <Footer />
      </div>
    );
  }

  document.title = `${getWebsiteName()} - ${t("title")}`;

  return (
    <div className="bg-white">
      <Header />

      <main>
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="relative max-w-xl mx-auto">
            <svg
              className="absolute left-full transform translate-x-1/2"
              width={404}
              height={404}
              fill="none"
              viewBox="0 0 404 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="85737c0e-0916-41d7-917f-596dc7edfa27"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={404}
                fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
              />
            </svg>
            <svg
              className="absolute right-full bottom-0 transform -translate-x-1/2"
              width={404}
              height={404}
              fill="none"
              viewBox="0 0 404 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="85737c0e-0916-41d7-917f-596dc7edfa27"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={404}
                fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
              />
            </svg>
            <div className="text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {t("title")}
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500">
                {t("description")}
              </p>
            </div>
            <div className="mt-12">
              <span className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <input type="hidden" name="form-name" value="contact" />
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.first_name")}*
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.last_name")}*
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.company")}*
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="organization"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.email")}*
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.phone")}
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 flex items-center">
                      <label htmlFor="country" className="sr-only">
                        {t("contact.phone_country")}
                      </label>
                      <select
                        id="country"
                        name="country"
                        className="h-full py-0 pl-4 pr-8 border-transparent bg-transparent text-gray-500 focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                        onChange={(e) => setPhoneCountry(e.target.value)}
                      >
                        <option>EU</option>
                        <option>US</option>
                        <option>CA</option>
                      </select>
                    </div>
                    <input
                      type="text"
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel"
                      className="py-3 px-4 block w-full pl-20 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      placeholder="+1 (555) 987-6543"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("contact.message")}*
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      defaultValue={""}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Switch
                        checked={agreed}
                        onChange={setAgreed}
                        className={classNames(
                          agreed ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        )}
                      >
                        <span className="sr-only">Agree to policies</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            agreed ? "translate-x-5" : "translate-x-0",
                            "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                    <div className="ml-3">
                      <p className="text-base text-gray-500">
                        {t("contact.agreed")}{" "}
                        <a
                          href={
                            "/" + (router.locale || "en") + "/legal/privacy"
                          }
                          className="font-medium text-gray-700 underline"
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          href={"/" + (router.locale || "en") + "/legal/terms"}
                          className="font-medium text-gray-700 underline"
                        >
                          Terms of use
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  {!!valid && (
                    <button
                      type="submit"
                      onClick={(e) => {
                        const subject = "" + getWebsiteName() + " Contact";
                        const body =
                          `Email: ${email}\n` +
                          `First Name: ${firstName}\n` +
                          `Last Name: ${lastName}\n` +
                          `Company: ${company}\n` +
                          `Phone: ${phoneCountry + " " + phone}\n` +
                          `Message: ${message}`;

                        fetch(
                          "https://api.metawallet.plus/api/customers/v1/contact-us",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              title: subject,
                              message: body,
                            }),
                          }
                        ).then(() => {
                          document.location = "/contact?success=1";
                        });

                        e.preventDefault();
                        return false;
                      }}
                      className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {t("contact.send")}
                    </button>
                  )}
                  {!valid && (
                    <button
                      disabled
                      type="submit"
                      className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gray-300"
                    >
                      {t("contact.send")}
                    </button>
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
